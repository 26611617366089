/* eslint-disable react/no-deprecated */
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import global_es from "./translations/es/global.json"
import global_en from "./translations/en/global.json"
const country = process.env.REACT_APP_COUNTRY;

country === "USA" || country === "JM"
  ?
  i18next.init({
    interpolation: { escapeValue: false },
    lng: "en", //INICIALIZA EN INGLES SI ES EN ESTADOS UNIDOS
    resources: {
      es: {
        global: global_es
      },
      en: {
        global: global_en
      }
    }
  })
  :
  i18next.init({
    interpolation: { escapeValue: false },
    lng: "es", //INICIALIZA EN ESPAÑOL
    resources: {
      es: {
        global: global_es
      },
      en: {
        global: global_en
      }
    }
  })

if (process.env.NODE_ENV !== "development")
  console.log = () => { };

ReactDOM.render(
  <>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </>
  ,
  document.getElementById('root')
);
