import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import React, { useContext } from 'react'
import './EstiloPages.css'
import { globalContext } from '../Context/GlobalContext'

export const SelectorCatalogo = () => {
  const { colorTheme } = useContext(globalContext)
  return (
    <div>
      <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading='lazy'></img>
      <Container fluid className={`contAlt bg-${colorTheme}`}>
        <h2 className='titleCat'>Please, select your language</h2>

        <div className='containerRmaButtons'>
          <hr />
          <Link to="/catalogo" className="butn5">Spanish</Link>
          <Link to="/catalogueEng" className="butn5">English</Link>
        </div>
      </Container>
    </div>
  )
}