/* eslint-disable react/prop-types */
import React from "react";
import { Container, Table } from "react-bootstrap";
import './DatosPersonales.css'; // Importa un archivo CSS para los estilos

// eslint-disable-next-line react/prop-types
export const DatosPersonales = ({ user }) => {
  return (
    <Container>
      <div className="datos-personales-container">
        <h3 className="datos-personales-title">Datos personales</h3>
        <Table striped bordered hover className="tabla-datos">
          <tbody>
            <tr>
              <td><h5>Número de usuario web:</h5></td>
              <td>{user.Id}</td>
            </tr>
            <tr>
              <td><h5>Nombre:</h5></td>
              <td>{user.Nombre}</td>
            </tr>
            <tr>
              <td><h5>Apellido:</h5></td>
              <td>{user.Apellido}</td>
            </tr>
            <tr>
              <td><h5>Email:</h5></td>
              <td>{user.Email}</td>
            </tr>
            <tr>
              <td><h5>DNI/CUIT:</h5></td>
              <td>{user.Cuit}</td>
            </tr>
            <tr>
              <td><h5>Teléfono:</h5></td>
              <td>{user.Domicilio_facturacion.Telefono}</td>
            </tr>
            <tr>
              <td><h5>Calle:</h5></td>
              <td>{user.Domicilio_facturacion.Domicilio}</td>
            </tr>
            <tr>
              <td><h5>Localidad:</h5></td>
              <td>{user.Domicilio_facturacion.Localidad}</td>
            </tr>
            <tr>
              <td><h5>Código postal:</h5></td>
              <td>{user.Domicilio_facturacion.Codigo_postal}</td>
            </tr>
            <tr>
              <td><h5>Condición de pago:</h5></td>
              <td>Efectivo</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Container>
  );
};
