import React from 'react'
import { Formulario } from '../Formulario/Formulario'

export const TrabajaConNosotros = () => {


  return (
    <div className={``}>
      <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading='lazy'></img>
      <Formulario />
    </div>
  )
}
