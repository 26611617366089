import './Tools.css'
import { Link } from 'react-router-dom'
import React from 'react'
import BannerCatalogo from './../../BannersBody/BannerCatalogo';

const Wireless = () => {
    return (
        <>
            <BannerCatalogo />
            <div className='tools-container'>
                <div className="links-container">
                    <Link to='/'>
                        <a href="#">Home</a>
                    </Link>
                    <br />
                    <a href="#">Configurators</a>
                </div>
                <h1 className='tools-h1'>Wireless</h1>
                <div className="ubiquiti-container">
                    <h2 className='tools-h2'>Ubiquiti - Utilities</h2>
                    <img
                        src={process.env.PUBLIC_URL + 'imagenes/marcas-grilla/ubiquiti.gif'}
                        alt="ubi"
                        width={150}
                    />
                </div>
                <ul className='tools-ul'>
                    <li className='tools-li'>Device Discovery Tool (Java - All platforms). <a href="https://www.ui.com/download/utilities/">Link</a></li>
                    <li className='tools-li'>Device Discovery Tool (Java - Mac OS X Bundle) <a href="https://www.ui.com/download/utilities/">Link</a></li>
                    <li className='tools-li'>Outdoor Wireless Link Calculator. <a href="https://www.ui.com/download/utilities/">Link </a></li>
                    <li className='tools-li'>Ubiquiti Discovery Tool (Chrome Extension). <a href="https://www.ui.com/download/utilities/">Link</a></li>
                    <li className='tools-li'>Utilities <a href="https://www.ui.com/download/utilities/">Link</a></li>
                </ul>
                <br />
                <div className="ubiquiti-container">
                    <h5 className='tools-h5'>Cambium Network</h5>
                    <img
                        src={process.env.PUBLIC_URL + 'imagenes/marcas-grilla/cambium.gif'}
                        alt="ubi"
                        width={150}
                    />
                </div>

                <ul className='tools-ul'>
                    <li className='tools-li'>Link Planner <a href="https://www.cambiumnetworks.com/products/planning-tools/link-planner">Link</a></li>

                </ul>
                <br />
                <div className="ubiquiti-container">
                    <h5 className='tools-h5'>Mikrotik</h5>
                    <img
                        src={process.env.PUBLIC_URL + 'imagenes/marcas-grilla/mikrotik.gif'}
                        alt="ubi"
                        width={150}
                    />
                </div>

                <ul className='tools-ul'>
                    <li className='tools-li'>Windiow <a href="www.mikrotik.com/download">Link</a></li>
                    <li className='tools-li'>Dude Manager <a href="https://mikrotik.com/thedude">Link</a></li>
                </ul>
            </div>
        </>
    )
}

export default Wireless