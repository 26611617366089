/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Banner = ({ src, srcEn, alt, href, email }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    // Si el idioma es EN y existe srcEn, usa srcEn, de lo contrario usa src por defecto
    const displaySrc = currentLanguage === 'en' && srcEn ? srcEn : src;

    // Si hay una propiedad email se va a mandar un mailto - Si hay un href un link a la direccion 
    const linkHref = email ? `mailto:${email}` : href || '#';

    return (
        <div style={{ marginBottom: '1em', marginTop: '1em' }}>
            <AnimationOnScroll animateIn="animate__fadeIn">
                <a href={linkHref} target={email ? "_self" : "_blank"} rel="noopener noreferrer">
                    <LazyLoadImage src={displaySrc} alt={alt} className='imagenesBody2' />
                </a>
            </AnimationOnScroll>
        </div>
    );
};

export default Banner;
