import { useContext, useCallback } from "react";
import { toast } from "react-toastify";
import { authContext } from "../../components/Context/AuthContext";
import { getDNIService } from "../../services/DNI_Feature";
import { globalContext } from "../../components/Context/GlobalContext";

const useFetchAuthorizedPerson = () => {
    const { setAuthorizedPerson } = useContext(globalContext);
    const { token } = useContext(authContext);

    const fetchAuthorizedPerson = useCallback(async () => {
        try {
            const data = await getDNIService(token);

            if (!data || data.length === 0) {
                toast.info("Aún no tienes documentos cargados.");
                setAuthorizedPerson([]);
            } else {
                setAuthorizedPerson(data);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setAuthorizedPerson([]);
            } else {
                toast.error("Hubo un error al obtener los autorizados.");
            }
            console.error("Error fetching authorized person:", error);
        }
    }, [token, setAuthorizedPerson]);

    return { fetchAuthorizedPerson };
};

export default useFetchAuthorizedPerson;
