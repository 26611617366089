import React, { useState, useRef } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { data } from "../../config/config.js"
import { FormSuccess } from './FormSuccess.jsx';
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineMail, AiOutlineMessage, AiOutlinePhone } from "react-icons/ai";
import "./FormularioStyle.css"
import { useTranslation } from 'react-i18next';

const apiContacto = process.env.REACT_APP_API_CONTACTO;

export const Formulario = () => {
    const [usuarioValido, setUsuarioValido] = useState(false);
    const [cvAdjuntado, setCvAdjuntado] = useState(false);
    const [values, setValues] = useState({
        nombre: '',
        mensaje: '',
        email: '',
        telefono: '',
        archivo: ''
    })
    const [t] = useTranslation("global")

    const handleInputChange = (e) => {
        if (e.target.name === 'archivo') {
            setCvAdjuntado(e.target.files.length > 0);
        }


        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const onChange = () => {
        if (captcha.current.getValue()) {
            console.log("El usuario no es un robot");
            setUsuarioValido(true);
        }
    };

    const captcha = useRef(null)

    const handleSubmit = () => {
        if (captcha.current.getValue()) {
            Swal.fire({
                title: 'Procesando su solicitud',
                html: 'Espere por favor',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
            <FormSuccess />
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Debes realizar la verificacion de seguridad!',
                showConfirmButton: true,
            })
        }
    }

    return (
        <div className="content-trabaja">
            <section className="section-login-register-trabaja">
                <br />
                <div className="form-box-register-trabaja">
                    <div className="form-value-register-trabaja">
                        <br />
                        <h1 className="logo-trabaja">
                            {t("trabajaConNosotros.titulo")}
                        </h1>
                        <br />
                        <form
                            action={`${apiContacto}/cv`}
                            encType="multipart/form-data"
                            method="POST"
                            onSubmit={handleSubmit}
                        >
                            <div className="input-box-login-register-trabaja">
                                <BsFillPersonFill
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ff5733' // Cambia el color del ícono
                                    }}
                                    className="icon-login-trabaja"
                                />
                                <input
                                    onChange={handleInputChange}
                                    name="nombre"
                                    value={values.nombre}
                                    className="textForm-trabaja"
                                    placeholder={t("formulario.placeholder-1")}
                                    type="text"
                                    maxLength={50}
                                    style={{
                                        color: 'white'
                                    }}
                                    required />
                                <label htmlFor="">{t("formulario.label-1")}</label>
                            </div>

                            <div className="input-box-login-register-trabaja">
                                <AiOutlineMail
                                    style={{
                                        marginRight: "-30px",
                                        color: '#00ff00'
                                    }}
                                    className="icon-login-trabaja"
                                />
                                <input
                                    onChange={handleInputChange}
                                    name="email"
                                    value={values.email}
                                    className="textForm-trabaja"
                                    placeholder={t("formulario.placeholder-2")}
                                    type="tel"
                                    style={{
                                        color: 'white'
                                    }}
                                    autoComplete='off'
                                    maxLength={50}
                                    required
                                />
                                <label htmlFor="">Email</label>
                            </div>

                            <div className="input-box-login-register-trabaja">
                                <AiOutlinePhone
                                    style={{
                                        marginRight: "-30px",
                                        color: 'yellow',
                                    }}
                                    className="icon-login-trabaja"
                                />
                                <input
                                    onChange={handleInputChange}
                                    name="telefono"
                                    value={values.telefono}
                                    placeholder={t("formulario.placeholder-3")}
                                    className="textForm-trabaja"
                                    type="number"
                                    style={{
                                        color: 'white'
                                    }}
                                    autoComplete='off'
                                    required
                                />
                                <label htmlFor="">{t("formulario.label-3")}</label>
                            </div>

                            <div className="input-box-login-register-trabaja">
                                <AiOutlineMessage
                                    style={{
                                        marginRight: "-30px",
                                        color: '#ff00ff' // Cambia el color del ícono
                                    }}
                                    className="icon-login-trabaja"
                                />
                                <input
                                    onChange={handleInputChange}
                                    name="mensaje"
                                    value={values.mensaje}
                                    placeholder={t("formulario.placeholder-4")}
                                    className="textForm-trabaja"
                                    type="text"
                                    style={{
                                        color: 'white'
                                    }}
                                    rows="2"
                                    autoComplete='off'
                                    maxLength={300}
                                />
                                <label htmlFor="">{t("formulario.label-4")}</label>
                            </div>


                            <div className="file-upload-container">
                                <label className="custom-file-upload">
                                    {cvAdjuntado ? t("formulario.cv-attached") : t("formulario.attach-cv")}
                                    <input
                                        onChange={handleInputChange}
                                        name="archivo"
                                        value={values.archivo}
                                        className="ingreseCV-trabaja"
                                        type="file"
                                        accept=".pdf,.doc, .docx"
                                        required
                                    />
                                </label>


                            </div>
                            <p className="text-register-title-trabaja">
                                {t("formulario.formato-archivo")}
                            </p>

                            <div className="captcha-container-trabaja">
                                <ReCAPTCHA
                                    className='mt-3 ml-3'
                                    onChange={onChange}
                                    ref={captcha}
                                    sitekey={data.sitekey}
                                />
                            </div>
                            {!usuarioValido && (
                                <button
                                    disabled
                                    type="submit"
                                    className="button-login-screen-trabaja"
                                    id="botonSubmit-trabaja"
                                >
                                    {t("formulario.btn-enviar")}
                                </button>
                            )}
                            {usuarioValido && (
                                <button
                                    type="submit"
                                    className="button-login-screen-trabaja"
                                    id="botonSubmit-trabaja"
                                >
                                    Enviar
                                </button>
                            )}

                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}