import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import './SuccessPF.css';
import React, { useEffect, useState, useContext } from 'react';
import { authContext } from '../../components/Context/AuthContext';
import { cartContext } from '../../components/Context/CartContext';

const SuccessPF = () => {
    const { idPedido, extPedido } = useParams();
    const { token } = useContext(authContext);
    const [pedidoData, setPedidoData] = useState(null);
    const { deleteByUser } = useContext(cartContext)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://testpa.solutionbox.com.pa/api/pedidos/orden/${idPedido}/${extPedido}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setPedidoData(response.data);
                deleteByUser()
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        };

        fetchData();
    }, [idPedido, extPedido]);

    // Función para formatear la fecha
    const formatFecha = (fecha) => {
        const date = new Date(fecha);
        return date.toLocaleDateString('es-ES');
    };
    return (
        <div className="successpf-container">
            <h1 className="successpf-title">¡Muchas gracias por su compra!</h1>
            <h2 className="successpf-subtitle">Su pago ha sido registrado correctamente</h2>
            {pedidoData && (
                <div>
                    <p className="successpf-info">ID del pedido: {pedidoData.Pedido_Nro}/{pedidoData.Pedido_Ext}</p>
                    <p className="successpf-info">Fecha: {formatFecha(pedidoData.Fecha)}</p>
                    <p className="successpf-info">Vendedor: {pedidoData.Vendedor}</p>
                    <p className="successpf-info">Condición de pago: {pedidoData.Condicion_Pago}</p>
                    <p className="successpf-info">Importe: {pedidoData.Importe}</p>
                    <p className="successpf-info">Moneda: {pedidoData.Moneda}</p>
                    <p className="successpf-info">Estado: {pedidoData.Estado}</p>

                    <p className="successpf-info">Productos:</p>
                    <ul>
                        {pedidoData.Items.map((item, index) => (
                            <li key={index}>
                                <p>Alias: {item.Alias}</p>
                                <p>Cantidad: {item.Cantidad}</p>
                                <p>Precio: {item.Precio} {item.Moneda}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <Link to="/" className="successpf-link">
                <button style={{ marginBlock: '10px', backgroundColor: 'red' }}>Volver al inicio</button>
            </Link>
        </div>
    );
}

export default SuccessPF;
