import React from "react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export const NotFound = () => {

    const navigate = useNavigate()
    const [contador, setContador] = useState(5)

    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 5000);
    }, [])

    const timer = setInterval(() => { setContador(contador - 1) }, 1000);
    setTimeout(() => { clearInterval(timer) }, 5000)

    return (
        <div className="divNotFound">
            <h1>Página no encontrada</h1>
            <span>404</span>
            <h2>Se le redigirá automáticamente a la página de inicio en {contador} segundos</h2>
        </div>
    )
}