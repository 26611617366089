import React from "react";
import GlobalStyles from "../../styles/GlobalStyles";
import { LayOut } from "../ProductDetail/LayOut/Index";

function DetalleDeProducto() {
  return (
    <>
      <LayOut />
      <GlobalStyles />
    </>
  );
}

export default DetalleDeProducto;