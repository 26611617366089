import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './ButtonWhatsapp.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ButtonWhatsapp = () => {
    const country = process.env.REACT_APP_COUNTRY;
    const [t] = useTranslation("global");

    // Determina si debe mostrar el botón de WhatsApp
    const tieneBoton = country === "USA" || country === "SV" || country === "PA" || country === "HN" || country === "CR" || country === "GT" || country === "DO" || country === "ARG";

    // Define el número de teléfono y el mensaje según el país
    let phoneNumber;
    let message;

    if (country === "ARG") {
        phoneNumber = '+5491134413853'; // Número para Argentina
        message = 'Hola, te contacto a través de la web de Solution Box Argentina'; // Mensaje para Argentina
    } else {
        phoneNumber = tieneBoton ? '+13057223825' : '+56945701494'; // Otros números
        message = 'Hola, te contacto a través de la web de Solution Box. Estoy interesado en ';
    }

    const openWhatsAppChat = () => {
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
    };

    const tooltip = (
        <Tooltip id="whatsapp-tooltip">{t("whatsapp.btn-whatsapp")}</Tooltip>
    );

    return (
        <>
            {tieneBoton && (
                <OverlayTrigger placement="left" overlay={tooltip}>
                    <div className="whatsapp-button" onClick={openWhatsAppChat}>
                        <FaWhatsapp size={60} color="#fff" style={{ margin: '0px' }} />
                    </div>
                </OverlayTrigger>
            )}
        </>
    );
}

export default ButtonWhatsapp;
