import React, { useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SwalFire } from '../helpers/SwalFire.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { data } from "../../config/config.js"
import { globalContext } from '../Context/GlobalContext.js';
const apiContacto = process.env.REACT_APP_API_CONTACTO;

export const ForgotPass = () => {
    const [usuarioValido, setUsuarioValido] = useState(false);
    const captcha = useRef(null);
    const onChange = () => {
        if (captcha.current.getValue()) {
            console.log('El usuario no es un robot');
            setUsuarioValido(true);
        }
    }
    const navigate = useNavigate();
    const [loggin, setLoggin] = useState(false);
    const [lenguaje, setLenguaje] = useState('');
    const [sistemaOperativo, setSistemaOperativo] = useState('');
    const [IP, setIP] = useState('');

    const [values, setValues] = useState({
        nombreApellido: '',
        email: '',
        razonSocial: '',
        nroCliente: '',
    });

    // -------------------------Traigo informacion del navegador del usuario-----------------

    useEffect(() => {
        axios
            .get('https://api.ipify.org?format=json')
            .then((response) => {
                const ip = response.data.ip;
                const language = navigator.language || navigator.userLanguage;
                const userAgent = navigator.userAgent;
                const operatingSystem = getOperatingSystem(userAgent);

                document.getElementById('languageInput').value = language;
                document.getElementById('operatingSystemInput').value = operatingSystem;
                document.getElementById('ipInput').value = ip;

                localStorage.setItem('language', language);
                localStorage.setItem('operatingSystem', operatingSystem);
                localStorage.setItem('ip', ip);

                setLenguaje(language);
                setSistemaOperativo(operatingSystem);
                setIP(ip);

            })
            .catch((error) => {
                console.log('Error al obtener la información del cliente:', error);
            });
    }, []);


    const datosActualizados = {
        ...values,
        language: lenguaje,
        operatingSystem: sistemaOperativo,
        ip: IP
    }

    console.log(datosActualizados)


    const handleInputChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    };

    const handleInputChangeNumber = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.name === "numero_cliente" ? Number(e.target.value) : e.target.value,
        });
    };
    const swalAlert = (status) => {
        if (status !== 201) {
            console.log(status);
            SwalFire('Server error', 'Try again later. sorry for the inconvenience', 'error');
            return false
        }
        SwalFire('Formulario procesado correctamente', 'Muchas gracias por su tiempo. Nuestro staff verificara sus datos. Dentro de 24 hs recibira su nueva password en su correo.', 'success');
        navigate('/');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (captcha.current.getValue()) {
            console.log('El usuario no es un robot')
        } else {
            console.log('Por favor acepta el captcha')
        }

        const language = navigator.language || navigator.userLanguage;
        const operatingSystem = getOperatingSystem(navigator.userAgent);
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const ip = ipResponse.data.ip;

        const updatedValues = {
            ...values,
            language,
            operatingSystem,
            ip
        };

        axios.post(`${apiContacto}/forgotPassword`,
            updatedValues
        )
            .then(
                Swal.fire({
                    title: 'Processing your request',
                    html: 'Please, wait',
                    allowEscapeKey: !loggin,
                    allowOutsideClick: !loggin,
                    didOpen: () => {
                        Swal.showLoading(loggin)
                    }
                })
            )
            .then(res => { console.log(res); swalAlert(res.status); setLoggin(false) })
            .catch(err => { console.log(err); swalAlert(err.response.status) })
    }


    function getOperatingSystem(userAgent) {
        const platforms = {
            win: 'Windows',
            mac: 'Mac OS',
            linux: 'Linux',
            ios: 'iOS',
            android: 'Android'
        };

        if (userAgent.indexOf('Win') !== -1) {
            return platforms.win;
        } else if (userAgent.indexOf('Mac') !== -1) {
            return platforms.mac;
        } else if (userAgent.indexOf('Linux') !== -1) {
            return platforms.linux;
        } else if (userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1) {
            return platforms.ios;
        } else if (userAgent.indexOf('Android') !== -1) {
            return platforms.android;
        }

        return 'Desconocido';
    }



    const { colorTheme } = useContext(globalContext)

    return (
        <div id='divAltaUsuarios'>
            <div id='divAltaNuevosUsuarios' className={`bg-${colorTheme}`}>
                <div className="formulario">
                    <form
                        onSubmit={handleSubmit}
                    >
                        <div className="user-details">
                            <div className="input-box">
                                <span className="details">Nombre Completo</span>
                                <input type="text" onChange={handleInputChange} name="nombreApellido" value={values.nombreApellido} placeholder='Nombre y Apellido..' required />
                            </div>
                            <div className="input-box">
                                <span className="details">Email</span>
                                <input type="email" autoComplete='off' onChange={handleInputChange} name="email" value={values.email} placeholder='Email...' required />
                            </div>
                            <div className="input-box">
                                <span className="details">Razón social</span>
                                <input type="text" onChange={handleInputChange} name="razonSocial" value={values.razonSocial} placeholder='Razón social...' />
                            </div>
                            <div className="input-box">
                                <span className="details">Numero de cliente</span>
                                <input type="number" onChange={handleInputChangeNumber} name="nroCliente" value={values.nroClientee} placeholder='Numero de cliente..' required />
                            </div>

                            <ReCAPTCHA
                                onChange={onChange}
                                ref={captcha}
                                sitekey={data.sitekey}
                            />
                            {!usuarioValido && <button disabled type="submit" className="buto">Enviar</button>}
                            {usuarioValido && <button type="submit" className="buto">Enviar</button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}