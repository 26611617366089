import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./BannerStyle.css"

const BannerCatalogo = () => {
  return (
    <div>
      <AnimationOnScroll animateIn="animate__fadeIn">
        <a href={process.env.PUBLIC_URL + "pdf/catalogoHD.pdf"} target="_blank" rel="noreferrer">
          <LazyLoadImage src={process.env.PUBLIC_URL + "banner/banner-catalogosolution.jpeg"}
            alt="Imagen marcas"
            className='imagenesBody desktopImg'
            placeholderSrc={process.env.PUBLIC_URL + "/imagenes/placeholder.png"}
            effect='blur'
          />
        </a>

        <a href={process.env.PUBLIC_URL + "pdf/catalogoHD.pdf"} target="_blank" rel="noreferrer">
          <LazyLoadImage src={process.env.PUBLIC_URL + "/imagenes/bannerCatalogoMobile.png"}
            alt="Imagen marcas"
            className='imagenesBody mobileImg mb-3'
            placeholderSrc={process.env.PUBLIC_URL + "/imagenes/placeholder.png"}
            effect='blur'
          />
        </a>
      </AnimationOnScroll>
    </div>
  )
}
export default BannerCatalogo;
