import React from 'react'
import './OfertaBanner.css'
import { IoPersonAddSharp } from "react-icons/io5";
import { useNavigate } from 'react-router';
import { GiClick } from "react-icons/gi";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const OfertaBanner = () => {
    const navigate = useNavigate()

    const [t] = useTranslation("global")

    return (
        <div style={{ maxWidth: '1200px', margin: '0 auto', overflow: 'hidden', padding: '20px' }} className='oferta-banner'>
            <div style={{ display: 'flex', justifyContent: 'center' }} className='bg-header'>
                <h2 style={{ color: 'white', }}>{t("ofertaBanner.titulo-oferta")}</h2>

            </div>

            <div className='bg-icons solicitarUsuario'>
                <div className='bloqueInicio' style={{ textAlign: 'center' }}>
                    <IoPersonAddSharp style={{ fontSize: '3rem' }} color='#219ebc' />
                    <p style={{ fontWeight: "bold", fontSize: '13px' }}>{t("ofertaBanner.primer-paso1")} <br /> {t("ofertaBanner.primer-paso2")}</p>
                </div>
                <div className='bloqueInicio' style={{ textAlign: 'center' }}>
                    <GiClick style={{ fontSize: '3rem' }} color='#219ebc' />
                    <p style={{ fontWeight: "bold", fontSize: '13px' }}>{t("ofertaBanner.segundo-paso1")}<br /> {t("ofertaBanner.segundo-paso2")}</p>
                </div>
                <div className='bloqueInicio' style={{ textAlign: 'center' }}>
                    <FaCheck style={{ fontSize: '3rem' }} color='#219ebc' />
                    <p style={{ fontWeight: "bold", fontSize: '13px' }}>{t("ofertaBanner.tercer-paso")} <br /></p>
                </div>
            </div>

            <div className="divider"></div>


            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-end', marginTop: '20px' }} className='bg-footer'>
                <button className='BtnHomePageUSWeb' onClick={() => navigate('/contacto')}>
                    {t("ofertaBanner.boton1")}
                </button>
                <button className='BtnHomePageUSWeb' onClick={() => navigate('/registrousuario')}>
                    {t("ofertaBanner.boton2")}
                </button>
            </div>
        </div>
    )
}

export default OfertaBanner
