/* eslint-disable react/prop-types */
import React from "react";
import { Description } from "./styles";

const Info = ({ data: marca }) => {
  const formatDescription = (description) => {
    return { __html: description.replace(/\n/g, "<br />") };
  };

  return (
    <Description>
      <div>
        <p>
          <span>{marca.Nombre} </span>
          <hr />
          <br />
          {marca.Descripcion_corta ? <div dangerouslySetInnerHTML={formatDescription(marca.Descripcion_corta)} /> : "Descripción del producto no disponible"}
          <br />
        </p>
      </div>
    </Description>
  );
};

export default Info;
