import React, { useContext } from 'react'
import { globalContext } from '../Context/GlobalContext'

const IframeHpe = () => {
    const { colorTheme } = useContext(globalContext)


    return (
        <div className={`container-fluid bg-${colorTheme} py-5`}>
            <div className='d-flex justify-content-center'>
                <iframe title="iframe con contenido de hpe" src="https://wcs-simplivity-hpwcs-esla-deereandcompany.swcontentsyndication.com/home" style={{ height: "700px", width: "40%", border: "none" }}></iframe>
                <iframe title="iframe compute solutions" src="https://wcs-computesolutionsesla-deereandcompany.swcontentsyndication.com/home" style={{ height: "700px", width: "40%", border: "none" }}></iframe>
            </div>
        </div>
    )
}

export default IframeHpe