/* eslint-disable no-undef */
const country = process.env.REACT_APP_COUNTRY;

export const data = require('./countries.json')[country];

export const banners = require('./banners.json');

export const capacitaciones = require('./capacitaciones.json');

export const marcas = require('./marcas.json');

export const bannersHome = require('./bannersHome.json');

export const empleados = require(`./Empleados/${country}_sucursal.json`);

export const legajo = require(`./legajo.json`);

export const condicionesRMA = require(`./condicionesRMA.json`);

export const condicionesRMA_Argentina = require(`./condicionesRMA_Argentina.json`);


