/* eslint-disable react/prop-types */
import React from "react";
import { Modal, Button } from "react-bootstrap";
import UploadDNI from "./UploadDNI";
import "./UploadDNIModal.css";

const UploadDNIModal = ({ onClose }) => {
    return (
        <Modal
            show={true}
            onHide={onClose}
            centered
            backdrop="static"
            keyboard={false}
            className="custom-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Cargar Documento</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                <UploadDNI />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default UploadDNIModal;
