import React from "react";
import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LocalStorage } from "../Data/LocalStorage";
import { SwalFire } from "../helpers/SwalFire";
import { toast } from "react-toastify";

export const authContext = createContext();

const urlApiLogin = process.env.REACT_APP_API_LOGIN;

const { Provider } = authContext;

const AuthContext = (props) => {
    const navigate = useNavigate();

    const [user, setUser] = LocalStorage("user");
    const [token, setToken] = LocalStorage("token");
    const [idUser, setIdUser] = LocalStorage("idUser");
    const [precompra, setPrecompra] = LocalStorage("precompra");
    const [clickLogin, setClickLogin] = useState(0);

    const desloguearse = (code = "") => {
        axios.post(`${urlApiLogin}/logout`,
            null,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then(SwalFire('Tiempo maximo de espera', 'Su sesion ha expirado, por favor vuelva a loguearse', 'success'))
            .then(setToken())
            .then(localStorage.clear())
            .then(res => console.log('deslogueo', res))
            .then(console.log("código =>", code))
            .then(navigate('/'))
            .catch(error => console.log('Logout con error', error));
    }

    const desloguearse2 = (code = "") => {
        axios
            .post(`${urlApiLogin}/logout`, null, {
                headers: { Authorization: "Bearer " + token },
            })
            .then((res) => {
                if (code === "RT") {
                    toast.error(`Error en la sesión: ${res.data.Mensaje}`);
                } else if (code === "CS") {
                    toast.success('Sesión cerrada: Se ha cerrado su sesión', {
                        className: 'custom-toast',
                        autoClose: 1500
                    });
                } else {
                    toast.error('Sesión cerrada: Error interno', {
                        className: 'custom-toast',
                        autoClose: 1500
                    });
                }
            })
            .then(setToken())
            .then(localStorage.clear())
            .then(navigate("/"))
            .then(window.location.reload()) //DESLOGUEO
            .catch((error) => console.log("Error de deslogueo", error.response));
    };


    const desloguearInicio = () => {
        axios.post(`${urlApiLogin}/logout`,
            null,
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then(setToken())
            .then(localStorage.clear())
            .then(() => console.log('logout inicio ok'))
            .catch(error => { console.log('Catch', error.response) });
    }


    const handleButtonClick = () => {
        const newClickLogin = clickLogin + 1;
        setClickLogin(newClickLogin);

        if (newClickLogin >= 3) {
            toast.error('Has realizado demasiados intentos de logueo.Por favvor, vuelve a intentarlo en 5 minutos.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    };

    const contextValue = {
        desloguearse,
        desloguearse2,
        desloguearInicio,
        token,
        setToken,
        idUser,
        setIdUser,
        user,
        setUser,
        precompra,
        setPrecompra,
        handleButtonClick
    };

    // eslint-disable-next-line react/prop-types
    return <Provider value={contextValue}>{props.children}</Provider>;
};

export default AuthContext;
