/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Container } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
import './Historial.css';

export const Historial = ({ compras }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const goToDetails = (param, id) => {
    navigate(`/profile/pedidos/${id}`, {
      state: {
        param,
      },
    });
  };

  if (!compras)
    return (
      <div className="divCompras empty-history">
        <h3>Historial de compras vacío</h3>
      </div>
    );

  // Ordenar la lista de compras por fecha de forma descendente
  const comprasOrdenadas = [...compras].sort((a, b) => {
    const fechaA = new Date(a.created_at).getTime();
    const fechaB = new Date(b.created_at).getTime();
    return fechaB - fechaA;
  });

  // Calcular el índice de los items a mostrar
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = comprasOrdenadas.slice(indexOfFirstItem, indexOfLastItem);

  // Calcular el numero total de páginas
  const totalPages = Math.ceil(comprasOrdenadas.length / itemsPerPage);

  return (
    <Container>
      <div className="historial-container">
        <h3 className="historial-title">Historial de pedidos realizados en la web</h3>
        <Table responsive striped bordered className="tablaHistorial">
          <thead>
            <tr>
              <th>Número de pedido</th>
              <th>Precio total</th>
              <th>Fecha de operacion</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => {
              const fechaFormateada = new Date(item.Fecha).toLocaleString('es-ES', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              });

              return (
                <tr key={item.Pedido_Nro}>
                  <td>{item.Pedido_Nro}/01</td>
                  <td>U$D {parseFloat(item.Importe).toFixed(2)}</td>
                  <td> {fechaFormateada}</td>
                  <td className="button-cell-historial">
                    <Button
                      className="ver-detalle-button d-flex align-items-center justify-content-between gap-2"
                      variant="secondary"
                      onClick={() => goToDetails(item, item.Pedido_Nro)}
                    >
                      Ver detalle   <TbFileInvoice size={20} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <div className="pagination-buttons-historial">
          <MdKeyboardArrowLeft
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className={`pagination-icon ${currentPage === 1 ? 'disabled' : ''}`}
          />
          <span className="page-number-historial">Pagina: {currentPage} de {totalPages}</span>
          <MdKeyboardArrowRight
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className={`pagination-icon ${currentPage === totalPages ? 'disabled' : ''}`}
          />
        </div>
      </div>
    </Container>
  );
};
