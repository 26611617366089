import Swal from 'sweetalert2'

export const SwalFire = (title, text, icon) => {
    return (
        Swal.fire({
            position: 'center',
            title: title,
            text: text,
            icon: icon,
            showConfirmButton: true,
        })
    )
}