import React from 'react'
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import "./ButtonWebCatStyles.css"

export const ButtonWebCat = () => {
    const navigate = useNavigate()
    const [t] = useTranslation("global")

    return (
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-end', marginTop: '20px' }} className='bg-footer flexBtns'>
            <button className='BtnHomePageWebCat' onClick={() => navigate('/capacitaciones')}>
                {t("ofertaBanner.boton3")}
            </button>
            <button className='BtnHomePageWebCat' onClick={() => navigate('/selectorcatalogo')}>
                {t("ofertaBanner.boton4")}
            </button>
        </div>
    )
}
