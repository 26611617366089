import React, { useContext, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from "axios";
import { Unauthorized } from '../helpers/Unauthorized';
import { DatosPersonales } from './DatosPersonales';
import { ChangePassword } from '../Login/ChangePassword';
import { Historial } from './Historial';
import './Profile.css';
import { authContext } from '../Context/AuthContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DNIList from '../shared/DNIList/DniList';
import UploadDNI from '../shared/UploadDNI/UploadDNI';

const apiPedidos = process.env.REACT_APP_API_PEDIDOS;

export const Profile = () => {
    const { token, user } = useContext(authContext);
    const [compras, setCompras] = useState();
    const navigate = useNavigate()
    const country = process.env.REACT_APP_COUNTRY

    useEffect(() => {
        if (!user) return false;

        axios
            .get(`${apiPedidos}/ordenes/cliente/${user.Cliente}?Limit=${10}&Offset=${0}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setCompras(res.data.pedidos);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response && err.response.status === 500) {
                    toast.error('No se puede ver esta sección en estos momentos');
                    navigate('/');
                } else if (err.response && err.response.status === 404) {
                    toast.error('No se puede ver la informacion en estos momentos');
                } else if (err.response && err.response.status === 401) {
                    toast.error('Aun no hay compras a su nombre.');
                } else {
                    console.log(err);
                }
            });
    }, []);


    if (!token) return <Unauthorized />;

    return (
        <>
            <div className="divPerfil">
                <h1>Perfil de control de {user.Nombre}</h1>
                <h4 className='mb-3'>Desde esta pantalla puede administrar su cuenta y ver información sobre sus pedidos</h4>
            </div>
            <Tabs
                defaultActiveKey="historial"
                id="fill-tab-example"
                className="mb-3"
                fill
            >
                <Tab eventKey="datos-personales" title="Datos personales">
                    <DatosPersonales user={user} />
                </Tab>
                <Tab eventKey="historial" title="Historial de compras">
                    <Historial compras={compras} />
                </Tab>
                <Tab eventKey="psw" title="Cambiar contraseña">
                    <ChangePassword />
                </Tab>

                {country === "ARG" && (
                    <Tab eventKey="dniadd" title="Cargar DNI">
                        <UploadDNI />
                    </Tab>
                )}

                {country === "ARG" && (
                    <Tab eventKey="dnilist" title="Listar DNI">
                        <DNIList />
                    </Tab>
                )}
            </Tabs>
        </>
    );
};
