import React, { useEffect, useState } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import styled from 'styled-components';

const Container = styled.div`
    display: flex; /* Utilizar Flexbox */
    justify-content: space-around; /* Espaciado entre los elementos */
    margin: 20px;
    max-width:94.3%;

    @media (max-width: 768px) {
        flex-direction: column; /* Apilar verticalmente en pantallas pequeñas */
        align-items: center; /* Centrar elementos */
    }
`;

const FeedContainer = styled.div`
    max-width: 400px; /* Ancho máximo del contenedor de Instagram */
    overflow-y: auto; /* Permitir desplazamiento vertical */
    height: 500px; /* Altura fija para el contenedor */
    border: 0px solid #ccc; /* Borde opcional */
    margin-right: 20px; /* Espacio entre Instagram y Facebook */

    @media (max-width: 768px) {
        margin-right: 0; /* Eliminar margen en pantallas pequeñas */
        margin-bottom: 20px; /* Espacio inferior entre componentes */
        width: 100%; /* Ancho completo en pantallas pequeñas */
    }
`;

const FacebookContainer = styled.div`
    width: 400px; /* Ancho fijo para el iframe de Facebook */

    @media (max-width: 768px) {
        display: none; /* Ocultar en pantallas pequeñas */
    }
`;

const Title = styled.h2`
    text-align: center; /* Centrar el texto */
    text-transform: uppercase; /* Convertir a mayúsculas */
    font-family: __Inter_723b8c, __Inter_Fallback_723b8c, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -1.2px;
    color: #000000;
    margin-top: 4.2em;
    font-weight: bold;

    @media (max-width: 768px) {
        margin-top: 10px;
    }

    span {
        color: violet; /* Color diferente para la parte "redes sociales" */
    }
`;

const SocialMediaApp = () => {
    const instagramAccessToken = 'IGQWRNZAmJyNmFjYno3cER5SjE4UGRrdDBGTVJwOUNEM29UeHc0QV9yZAVAzTURacVZAkTzZAKOEUzMl95cVFmcVF2YlJvcFB4Qm45bWE1LWw2ZAmFkdEhNSk1XZAmk0cFdkaC1vVW1FeDdUb2JBeXpVQ19VV0xJY0k3bDAZD';
    const instagramUserId = '9188268014557916';
    const facebookPageUrl = 'https://www.facebook.com/solutionboxarg';
    const [instagramPostUrls, setInstagramPostUrls] = useState([]);

    useEffect(() => {
        const fetchInstagramPosts = async () => {
            try {
                const response = await fetch(`https://graph.instagram.com/${instagramUserId}/media?fields=id,caption,permalink&access_token=${instagramAccessToken}`);
                if (!response.ok) {
                    throw new Error('Error fetching Instagram posts');
                }
                const data = await response.json();
                // Limitar a un máximo de 5 publicaciones
                const urls = data.data.slice(0, 5).map(post => post.permalink);
                setInstagramPostUrls(urls);
            } catch (error) {
                console.error('Error fetching Instagram posts:', error);
            }
        };

        fetchInstagramPosts();
    }, [instagramAccessToken, instagramUserId]);

    return (
        <Container>
            <Title>
                Conoce nuestras <span>redes sociales</span>
            </Title>
            <FeedContainer>
                {instagramPostUrls.map((url, index) => (
                    <div key={index} style={{ marginBottom: '20px' }}>
                        <InstagramEmbed
                            url={url}
                            width={400}
                            captioned={true} // Si deseas mostrar el pie de foto
                        />
                    </div>
                ))}
            </FeedContainer>
            <FacebookContainer>
                <iframe
                    src={`https://www.facebook.com/plugins/page.php?href=${encodeURIComponent(facebookPageUrl)}&tabs=timeline&width=400&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true`}
                    width="400"
                    height="500"
                    style={{ border: 'none', overflow: 'hidden' }}
                    allow="encrypted-media"
                ></iframe>
            </FacebookContainer>
        </Container>
    );
};

export default SocialMediaApp;
