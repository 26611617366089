import "./AltaUsuariosStyle.css";

import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SwalFire } from '../helpers/SwalFire';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

const apiContacto = process.env.REACT_APP_API_CONTACTO;

export const AltaUsuarios = () => {

    const navigate = useNavigate();
    const [loggin, setLoggin] = useState(false);

    const [values, setValues] = useState({
        nombre: '',
        email: '',
        razon_social: '',
        cargo: '',
    });

    const handleInputChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    };

    const swalAlert = (status) => {
        if (status !== 201) {
            SwalFire('Error interno', 'Intente mas tarde. Disculpe las molestias', 'error');
            //return false
        }
        SwalFire('Mensaje enviado correctamente', 'Muchas gracias por su mensaje, nos estaremos comunicando a la brevedad', 'success');
        navigate('/');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${apiContacto}/solicitud-usuario`,
            values
        )
            .then(
                Swal.fire({
                    title: 'Procesando su solicitud',
                    html: 'Espere por favor',
                    allowEscapeKey: !loggin,
                    allowOutsideClick: !loggin,
                    didOpen: () => {
                        Swal.showLoading(loggin)
                    }
                })
            )
            .then(res => { console.log(res); swalAlert(res.status); setLoggin(false) })
            .catch(err => { console.log(err); swalAlert(err.response.status) })
    }

    return (
        <div id='divAltaUsuarios'>
            <div>
                <h4>SOLUTION BOX SRL</h4>
                <p><b>Horario</b>:</p><p>Lunes a Viernes de 8.30 a 18.30hs</p>
                <p><b>Teléfono</b>:</p><p>+595217293600</p>
                <p><b>E-mail</b>:</p><p>info@solutionbox.com.py</p>
                <p><b>Dirección</b>:</p><p>Avda. Gral Santos esq. Concepción Leyes de Chavez (Barrio Jara Complejo Barrail Depósito 4, Asunción, Paraguay)</p>
            </div>



            <div id='divAltaNuevosUsuarios'>
                <h2>Alta de nuevos usuarios</h2>
                <p className="textCenter"><b>Para obtener su clave web</b>, descargue el siguiente contrato de adhesión, y envíelo firmado a nuestras oficinas.</p>
                <a href='pdf/contrato.pdf' target='_blank' rel='noreferrer' className='btnAlta'>
                    <Button variant='danger'>
                        Descargar contrato
                    </Button>
                </a>
                <p className="textCenter"><b>Si Ud. aún no es cliente de Solution Box</b>, descargue el siguiente formulario y envíe los documentos solicitados a nuestras oficinas.</p>
                <Button variant='danger' className='btnAlta'>
                    <a href='pdf/formularioRegistro.pdf' target='_blank' rel='noreferrer'>
                        Descargar formulario
                    </a>
                </Button>

                <p className="textCenter">Si tiene alguna duda, complete el siguiente formulario y un ejecutivo se pondrá en contacto con Ud.</p>

                <div className="formulario">
                    <form
                        onSubmit={handleSubmit}
                    >
                        <div className="user-details">
                            <div className="input-box">
                                <span className="details">Nombre y apellido</span>
                                <input type="text" onChange={handleInputChange} name="nombre" value={values.nombre} placeholder='Ingrese su nombre por favor...' required />
                            </div>

                            <div className="input-box">
                                <span className="details">Email (usuario administrador) </span>
                                <input type="email" autoComplete='off' onChange={handleInputChange} name="email" value={values.email} placeholder='Ingrese su email por favor...' required />
                            </div>

                            <div className="input-box">
                                <span className="details">Razón social</span>
                                <input type="text" onChange={handleInputChange} name="razon_social" value={values.razonSocial} placeholder='Ingrese su razon social por favor...' required />
                            </div>

                            <div className="input-box">
                                <span className="details">Cargo</span>
                                <input type="text" onChange={handleInputChange} name="cargo" value={values.cargo} placeholder='Ejemplo: Vendedor, Gerente comercial, etc...' required />
                                <button type="submit" className="buto">Enviar</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
