import React from 'react';
import { Link } from 'react-router-dom';
import BannerCatalogo from '../../BannersBody/BannerCatalogo';

const DigitalSecurity = () => {
    return (
        <>
            {/* Preload the banner image */}
            <link
                rel='preload'
                as='image'
                href={process.env.PUBLIC_URL + 'assets/Banners/banner-catalogo.jpg'}
            />
            <BannerCatalogo />
            <div className='tools-container'>
                <div className='links-container'>
                    <Link to='/'>
                        <a href="#">Home</a>
                    </Link>
                    <br />
                    <a href="#">Configurators</a>
                </div>
                <h1 className='tools-h1'>Digital Security</h1>
                <div className='ubiquiti-container'>
                    <h2 className='tools-h2'>WatchGuard</h2>
                    <img
                        src={process.env.PUBLIC_URL + 'imagenes/marcas-grilla/watchguard-logo_0-01.png'}
                        alt='ubi'
                        width={150}
                    />
                </div>
                <ul className='tools-ul'>
                    <li className='tools-li'>
                        Appliance Sizing Tool{' '}
                        <a href='https://www.watchguard.com/wgrd-resource-center/watchguard-appliance-sizing-tool'>
                            Link
                        </a>
                    </li>
                    <li className='tools-li'>
                        Compare Appliances{' '}
                        <a href='https://www.watchguard.com/wgrd-products/appliances-compare'>
                            Link
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default DigitalSecurity;
