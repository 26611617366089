import React from "react"
import { Button } from "react-bootstrap"
import { useContext } from 'react'
import './Navbar.css'
import { authContext } from "../Context/AuthContext"

const BtnLogout = () => {

    const { desloguearse2 } = useContext(authContext)

    return (
        <div>
            <Button variant="danger" onClick={() => desloguearse2('CS')}>
                Cerrar sesión
            </Button>
        </div>
    )
}

export default BtnLogout
