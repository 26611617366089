import React from 'react'
import { useContext } from 'react'
import { cartContext } from '../Context/CartContext'

// eslint-disable-next-line react/prop-types
const BtnCartRestar = ({ item }) => {

  const { addToCart } = useContext(cartContext)

  return (
    <div>
      <button className='restar'
        onClick={() => {
          // eslint-disable-next-line react/prop-types
          if (item.contador > 1) {
            addToCart(item, -1)
          }
          else {
            return false
          }
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4"
          style={{ width: '20px', height: '20px', color: 'red' }}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
        </svg>
      </button>
    </div>
  )
}

export default BtnCartRestar