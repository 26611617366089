import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import axios from 'axios';
import { Button, Col, Container, Row } from "react-bootstrap"
import BtnCartSumar from './BtnCartSumar'
import BtnCartRestar from './BtnCartRestar'
import BtnEliminar from './BtnEliminar'
import { Unauthorized } from '../helpers/Unauthorized'
import { SpinnerGif } from '../helpers/SpinnerGif';
import { cartContext } from '../Context/CartContext';
import { authContext } from '../Context/AuthContext';
import { useTranslation } from 'react-i18next';
import styles from './Cart.module.css';

const urlApiPedidos = process.env.REACT_APP_API_PEDIDOS

const Cart = () => {

  const { cartLocal, setPrecompra } = useContext(cartContext)
  const { token, user, desloguearse } = useContext(authContext)
  const navigate = useNavigate()
  const totalPrecio = cartLocal.reduce((total, producto) => total + producto.Precio * producto.contador, 0)
  const [items, setItems] = useState()
  const [loading, setLoading] = useState(false)
  const [t] = useTranslation("global")
  const country = process.env.REACT_APP_COUNTRY;

  const mapearData = () => {
    const array = []
    for (let i = 0; i < cartLocal.length; i++) {
      let data = {
        Alias: cartLocal[i].Alias,
        Precio: cartLocal[i].Precio,
        Cantidad: cartLocal[i].contador,
        Moneda: cartLocal[i].Moneda_Signo,
      }
      array.push(data)
    }
    setItems(array)
  }

  useEffect(() => {
    mapearData()
  }, [])


  const procesarPrecompra = () => {

    let condicion_pago = {
      Codigo: user.Condicion_Pago.Codigo,
      Descripcion: user.Condicion_Pago.Descripcion
    }

    let tipo_de_entrega = {
      Codigo: user.Tipo_entrega.Codigo,
      Descripcion: user.Tipo_entrega.Descripcion
    }


    console.log('este es el tipo de entrega inicial al procesar la precomopra', tipo_de_entrega)

    let dir_entrega;

    if (tipo_de_entrega.Descripcion === 'ENTREGA') {
      dir_entrega = {
        Domicilio: user.Domicilio_entrega.Domicilio,
        Localidad: user.Domicilio_entrega.Localidad,
        codigoPostal: user.Domicilio_entrega.Codigo_postal,
        Codigo_Prov: user.Domicilio_entrega.Codigo_Prov,
        Pais: user.Domicilio_entrega.Pais
      }
    } else {
      dir_entrega = null

    }

    let Precompra = {
      items: items,
      cond_pago: condicion_pago,
      tipo_entrega: tipo_de_entrega,
      Direccion_entrega: dir_entrega
    }


    console.log(Precompra, 'esta es la precompra que se postea desde el cart con la data del usuario')

    axios.post(`${urlApiPedidos}/proforma`, { precompra: Precompra }, { headers: { 'Authorization': 'Bearer ' + token } })
      .then(setLoading(true))
      .then(res => {
        if (res.status === 401) {
          setLoading(false);
          desloguearse()
        }
        setPrecompra(res.data);
        navigate('/checkout')
      })
      .catch(err => {
        setLoading(false); console.log('ERROR CART', err);
      })
  }

  if (!token) return <Unauthorized />

  if (totalPrecio === 0) {
    return (
      <div className={styles.carritoVacio}>
        <h1>{t("carrito.carrito-vacio")}</h1>
        <br />
        <hr />
        <h5 className='mb-3'>{t("carrito.explora-catalogo")}</h5>
        <Button variant="dark" className={styles.btnVolver} onClick={() => navigate(`/`)}>Ir al inicio</Button>
      </div>
    )
  }

  if (loading) return <SpinnerGif text={t("carrito.procesar-compra")} />


  let taxSucursal = "";

  if (country === "PA") {
    taxSucursal = "ITBMS";
  } else {
    taxSucursal = "IVA";
  }

  return (
    <div style={{ backgroundColor: '#ededed' }}>
      <Container className={styles.mainContainer}>
        <Row className={styles.row}>
          <Col md={7} className={`${styles.column} ${styles.leftColumn}`}>
            <div className={styles.cartRenderizado}>
              <h4>{t("pedidos.orden")}</h4>
              <hr />
              <Table responsive className={`${styles.tableCart}`}>
                <thead>
                  <tr>
                    <th><span>{t("pedidos.cantidad")}</span></th>
                    <th></th>
                    <th>{t("pedidos.descripcion")}</th>
                    <th>{t("pedidos.numero-parte")}</th>
                    <th>Marca</th>
                    {country !== "USA" ? <th>{t("pedidos.unitario")} (Sin {taxSucursal})</th> : <th>{t("pedidos.unitario")}</th>}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cartLocal.map(item => (
                    <tr key={item.Alias} className={styles.tableRow}>
                      <td>
                        <div className={styles.modificarUnidades}>
                          <BtnCartRestar item={item} />
                          <span>{item.contador}</span>
                          <BtnCartSumar item={item} />
                        </div>
                      </td>
                      <td></td>
                      <td>{item.Nombre}</td>
                      <td>{item.Alias}</td>
                      <td>{item.Marca}</td>
                      <td>U$S {item.Precio}</td>
                      <td><BtnEliminar item={item} /></td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className={styles.tableFooter}>
                    <td colSpan={4}></td>
                    <td></td>
                    <td></td>
                    {/* <td>{country !== "USA" ? `Precio total (Sin ${taxSucursal})` : "Total"}</td> */}
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan={4}></td>
                    <td></td>
                    <td></td>
                    {/* <td><b>U$S {(totalPrecio).toFixed(2)}</b></td> */}
                    <td></td>
                  </tr>
                </tfoot>
              </Table>
              {country !== "USA" && (
                <tr>
                  <td>*{t("carrito.calculo-1")} {taxSucursal} {t("carrito.calculo-2")}</td>
                </tr>
              )}
            </div>
          </Col>
          <Col md={5} className={`${styles.column} ${styles.rightColumn}`}>
            <div className={styles.additionalContent}>
              <h4>Resumen de compra</h4>
              <hr />
              <div className={styles.priceSummary}>
                {country !== "USA" ? <h5>{t("pedidos.total")} (Sin {taxSucursal})</h5> : <th>{t("pedidos.total")}</th>}
                <h4>U$S {(totalPrecio).toFixed(2)}</h4>
              </div>
              <br />
              <hr />
              <br />
              <div className={styles.btnFinalizarCompra}>
                <hr />
                <Button onClick={procesarPrecompra} className={styles.btnConfirmarCompra}>
                  <i className="fa-sharp fa-solid fa-basket-shopping"></i>
                  {t("pedidos.confirm")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  )
}

export default Cart