import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import axios from 'axios';
import { Unauthorized } from '../helpers/Unauthorized';
import { Facturacion } from "./Facturacion";
import { SpinnerGif } from "../helpers/SpinnerGif";
import { SwalFire } from '../helpers/SwalFire';
import './Checkout.css';
import { cartContext } from '../Context/CartContext';
import { authContext } from '../Context/AuthContext';
import { useTranslation } from 'react-i18next';
import Steps from '../shared/StepsCheckout/StepsCheckout';
import ProcesarCompraButton from '../shared/ProcesarCompraButton/ProcesarCompraButton';
import { globalContext } from '../Context/GlobalContext';
import { actualizarTiposDeEntrega } from '../../utilities/handlers/handleActualizarTiposDeEntrega';
import { CartRender } from './CartRender';

const urlApiCheckout = process.env.REACT_APP_API_CHECKOUT;

const Checkout = () => {
  const { cartLocal, deleteByUser, selectedFile } = useContext(cartContext);
  const { token, user, desloguearse } = useContext(authContext);
  const { info, tiposDeEntrega, isEntregaSelected,
    selectedAuthorizedPerson
  } = useContext(globalContext);
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [t] = useTranslation("global");
  const [precompra, setPrecompra] = useState(JSON.parse(localStorage.getItem('precompra')));
  const hasContent = selectedFile && Object.keys(selectedFile).length > 0;
  const [actualizando, setActualizando] = useState(false);
  const country = process.env.REACT_APP_COUNTRY;
  const [currentStep, setCurrentStep] = useState(1);
  const formatosAceptados = ['image/jpeg', 'image/png', 'image/jpg'];

  if (!token && !precompra) return <Unauthorized />;

  let taxPesos = [];
  let taxDolares = [];

  function guardar(moneda, array) {
    for (let item in moneda) {
      if (moneda[item] > 0) {
        let nuevo = { [item]: moneda[item].toLocaleString() };
        array.push(nuevo);
      }
    }
  }

  let itemsMapeados = [];

  function mapearItems(items) {
    for (let i = 0; i < items.length; i++) {
      itemsMapeados.push({
        Alias: items[i].Alias,
        Moneda: items[i].Moneda,
        Precio: items[i].Precio,
        Cantidad: items[i].Cantidad,
      });
    }
  }

  guardar(precompra.Subtotal_Pesos, taxPesos);
  guardar(precompra.Subtotal_Dolares, taxDolares);

  mapearItems(precompra.items);

  const data = {
    precompra: precompra,
    cliente: {
      ID_CLIENTE: user.Cliente,
      name_: user.Nombre,
      lastname: user.Apellido,
      email: user.Email,
      dni: user.Cuit,
      telefono: user.Domicilio_facturacion.Telefono,
      calle: user.Domicilio_facturacion.Domicilio,
      numero: '1',
      localidad: user.Domicilio_facturacion.Localidad,
      codigoPostal: user.Domicilio_facturacion.Codigo_postal,
    },
  };

  if (selectedFile && hasContent) {
    data.archivoTransferencia = selectedFile;
  }

  const goToSuccess = (param) => {
    navigate(`/success`, {
      state: {
        param
      }
    });
  };

  const handleActualizarEntrega = async () => {
    await actualizarTiposDeEntrega(info, tiposDeEntrega, precompra, setPrecompra, token, user, setActualizando);
  };

  const enviarOrden = async (e) => {
    e.preventDefault();
    setActualizando(true);
    const precompraLocalStorage = JSON.parse(localStorage.getItem('precompra'));

    if (!precompraLocalStorage) {
      console.error('La información de precompra en localStorage no está disponible o no está actualizada.');
      setActualizando(false);
      return;
    }

    data.precompra = precompraLocalStorage;

    if (!info.pago || !info.entrega) {
      setActualizando(false);
      SwalFire(t("checkout.seleccione-metodo"));
      return;
    }

    // Verificar que el archivo seleccionado tenga un formato válido
    if (selectedFile && !formatosAceptados.includes(selectedFile.type)) {
      setActualizando(false);
      SwalFire(
        'Error',
        'El formato de archivo no es válido. Por favor, sube una imagen en formato JPEG, PNG o JPG.',
        'warning'
      );
      return;
    }

    let AxiosOptions;
    if (selectedFile && hasContent && info.pago === "PRE PAY" && info.entrega === "RETIRA") {
      const formPedidoTransferencia = new FormData();
      formPedidoTransferencia.append('precompra', JSON.stringify(data.precompra));
      formPedidoTransferencia.append('cliente', JSON.stringify(data.cliente));
      const contentFile = new Blob([selectedFile.content], { type: selectedFile.type });
      formPedidoTransferencia.append('archivoTransferencia', contentFile, selectedFile.filename);
      AxiosOptions = {
        method: 'post',
        url: `${urlApiCheckout}/pedido/transferencia/success`,
        headers: {
          'Authorization': 'Bearer ' + token,
          "Content-Type": "multipart/form-data"
        },
        data: formPedidoTransferencia
      };
    } else if (country === "UY" && hasContent) {
      const formPedidoTransferencia = new FormData();
      formPedidoTransferencia.append('precompra', JSON.stringify(data.precompra));
      formPedidoTransferencia.append('cliente', JSON.stringify(data.cliente));
      const contentFile = new Blob([selectedFile.content], { type: selectedFile.type });
      formPedidoTransferencia.append('archivoTransferencia', contentFile, selectedFile.filename);
      AxiosOptions = {
        method: 'post',
        url: `${urlApiCheckout}/pedido/transferencia/success`,
        headers: {
          'Authorization': 'Bearer ' + token,
          "Content-Type": "multipart/form-data"
        },
        data: formPedidoTransferencia
      };
    } else {
      AxiosOptions = {
        method: 'post',
        url: `${urlApiCheckout}/pedido/success`,
        headers: { 'Authorization': 'Bearer ' + token },
        data: data
      };
    }

    try {
      const res = await axios(AxiosOptions);
      setActualizando(false);
      if (res.status === 401) {
        setLoad(false);
        desloguearse();
        return;
      }
      if (res.status === 429) {
        setLoad(false);
        return;
      }
      if (res.status === 200) {
        setLoad(false);
        goToSuccess(res.data);
        deleteByUser();
      }
    } catch (err) {
      setLoad(false);
      setActualizando(false);

      const errorMessage = err.response?.data?.error || err.message || "Ha ocurrido un error.";

      SwalFire('Error', errorMessage, 'warning')
        .then(() => {
          if (err.response?.status === 401) {
            desloguearse();
            navigate('/');
          }
        });
    }
  };

  if (load) return <SpinnerGif text={t("checkout.procesando-compra")} />;

  if (!token) return <Unauthorized />;

  return (
    <Container>
      <div className="stepper-container">
        <Steps currentStep={currentStep} />
        <div className="step-content">
          {currentStep === 1 && (
            <Facturacion
              user={user}
              enviarOrden={enviarOrden}
              handleActualizarEntrega={handleActualizarEntrega}
              actualizando={actualizando}
              setActualizando={setActualizando}
            />
          )}
          {currentStep === 2 && (
            <CartRender cartLocal={cartLocal} />
          )}
          {currentStep === 3 && (
            <ProcesarCompraButton
              onClick={enviarOrden}
              label="Procesar compra"
              isProcessing={actualizando}
            />
          )}

          <div className="stepper-buttons">
            {currentStep > 1 && (
              <Button variant="secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                <i className="fa-sharp fa-solid fa-circle-chevron-left"></i>
                Anterior
              </Button>
            )}
            {currentStep < 3 && (
              <Button
                disabled={!isEntregaSelected
                  || (info.entrega === "RETIRA" && !selectedAuthorizedPerson && country === "ARG" // Esto queda comentado para que deje pasar al siguiente step hasta que este el back
                  )
                }
                variant="secondary"
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                Siguiente
                <i style={{ marginLeft: '7px' }} className="fa-sharp fa-solid fa-circle-chevron-right"></i>
              </Button>
            )}

          </div>
        </div>
      </div>
    </Container>
  );
};

export default Checkout;
