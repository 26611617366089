/* eslint-disable react/prop-types */
import React from 'react';
import { FaShoppingCart, FaCheckCircle, FaTruck } from 'react-icons/fa';
import './StepperCheckout.css';

const Steps = ({ currentStep }) => {
    return (
        <>
            <div className="steps-container">
                <div className={`step ${currentStep === 1 ? 'active' : 'inactive'}`}>
                    <FaTruck size={'1em'} className="step-icon" />
                    <p className="step-text">Condiciones de Entrega</p>
                </div>
                <div className={`step ${currentStep === 2 ? 'active' : 'inactive'}`}>
                    <FaShoppingCart size={'1em'} className="step-icon" />
                    <p className="step-text">Resumen de pedido</p>
                </div>
                <div className={`step ${currentStep === 3 ? 'active' : 'inactive'}`}>
                    <FaCheckCircle size={'1em'} className="step-icon" />
                    <p className="step-text">Confirmación de compra</p>
                </div>
            </div>
            <div className="divider"></div>
        </>
    );
};

export default Steps;
