import React, { useState, useRef, useContext } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { data } from "../../config/config.js"
import { globalContext } from '../Context/GlobalContext.js';
import { useTranslation } from 'react-i18next';

export const Rma2 = () => {

    const [values, setValues] = useState({
        nombre: '',
        razonSocial: '',
        factura: '',
        fechaFactura: '',
        email: '',
        vendedor: '',
        marca: '',
        problema: '',
        telefono: '',
        Serie: '',
        macId: '',
        Cliente: '',
        rmaGrandstream: ''
    })

    const handleInputChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const captcha = useRef(null)

    const handleSubmit = (e) => {
        if (captcha.current.getValue()) {
            Swal.fire({
                icon: 'success',
                title: 'Mensaje enviado correctamente!',
                text: 'Muchas gracias por su mensaje, nos estaremos comunicando a la brevedad',
            }).then(function () {
                window.location = "/";
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Debes realizar la verificacion de seguridad!',
            })
            e.preventDefault()
            console.log("acepta el captcha")
        }
    }

    const { colorTheme } = useContext(globalContext)

    const [t] = useTranslation("globals")

    return (

        <>
            {
                <div className={`container-fluid py-5 bg-${colorTheme}`}>
                    <div className={` formulario `}>
                        <h2>{t("rma.titulo-form1")}{t("rma.titulo-form2")}APC-SCHNEIDER</h2>
                        <hr />
                        <form className={`text-${!colorTheme === 'dark' ? 'light' : 'dark'} formAlignCenter`} action="https://www.solutionbox.com.uy/api/contacto/rma" method="POST" onSubmit={handleSubmit}>
                            <p className="formP">{t("formulario.label-1")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="nombre"
                                value={values.nombre}
                                className="textForm"
                                type="text"
                                placeholder={t("formulario.placeholder-1")}
                                required
                            />
                            <p className="formP">{t("formulario.label-5")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="razonSocial"
                                value={values.razonSocial}
                                className="textForm"
                                type="text"
                                placeholder={t("formulario.placeholder-5")}
                                required
                            />
                            <p className="formP">{t("formulario.label-6")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="Cliente"
                                value={values.Cliente}
                                className="textForm"
                                type="number"
                                placeholder={t("formulario.placeholder-6")}
                                required
                            />
                            <p className="formP">{t("formulario.label-3")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="telefono"
                                value={values.telefono}
                                className="textForm"
                                type="number"
                                placeholder={t("formulario.placeholder-3")}
                                required
                            />
                            <p className="formP">{t("formulario.label-2")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="email"
                                value={values.email}
                                className="textForm"
                                type="email"
                                placeholder={t("formulario.placeholder-2")}
                                autoComplete='off'
                                required
                            />
                            <p className="formP">{t("formulario.factura")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="factura"
                                value={values.factura}
                                className="textForm"
                                type="number"
                                placeholder={t("formulario.placeholder-fecha-factura")}
                                required
                            />
                            <p className="formP">{t("formulario.fecha-factura")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="fechaFactura"
                                value={values.fechaFactura}
                                className="textForm"
                                type="date"
                                placeholder={t("formulario.fecha-factura")}
                                autoComplete='off'
                                required
                            />
                            <p className="formP">{t("formulario.vendedor")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="vendedor"
                                value={values.vendedor}
                                className="textForm"
                                type="text"
                                placeholder={t("formulario.placeholder-vendedor")}
                                autoComplete='off'
                                required
                            />
                            <p className='formP'>{t("formulario.label-modelo-apc")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="marca"
                                value={values.marca}
                                className="textForm"
                                type="text"
                                placeholder={t("formulario.placeholder-apc")}
                                autoComplete='off'
                                required
                            />
                            <p className='formP'>{t("formulario.label-6")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="Serie"
                                value={values.Serie}
                                className="textForm"
                                type="text"
                                placeholder={t("formulario.placeholder-numero-serie")}
                                required
                            />
                            <p className='formP'>{t("formulario.label-codigo-producto")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="macId"
                                value={values.macId}
                                className="textForm"
                                type="text"
                                placeholder={t("formulario.placeholder-codigo-producto")}
                                required
                            />
                            <p className='formP'>{t("formulario.label-numero-caso-aprobado")}</p>
                            <input style={{ height: "1%" }}
                                onChange={handleInputChange}
                                name="rmaGrandstream"
                                value={values.rmaGrandstream}
                                className="textForm"
                                type="text"
                                placeholder={t("formulario.placeholder-numero-caso-aprobado")}
                                required
                            />
                            <textarea style={{ height: "5%" }}
                                onChange={handleInputChange}
                                name="problema"
                                value={values.problema}
                                className="textForm"
                                type="text"
                                placeholder={t("formulario.problema")}
                                rows="2"
                                autoComplete='off'
                                required
                            />
                            <ReCAPTCHA
                                className='mt-3 ml-3'
                                ref={captcha}
                                sitekey={data.sitekey}
                            />
                            <button type="submit" className="butn">{t("formulario.btn-enviar")}</button>
                        </form>
                    </div>
                </div>
            }
        </>
    )
}