import React, { useRef, useState } from 'react'
import './formularioDenuncia.css';
import Accordion from './Accordion';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { data } from "../../config/config.js"
import { useTranslation } from 'react-i18next';

const FormularioDenuncia = () => {
  // Estado para almacenar los valores del formulario
  const [formData, setFormData] = useState({
    tipoInfraccion: [],
    otroTipoInfraccion: '',
    relacionCompania: '',
    pais: '',
    ciudad: '',
    estadoProvincia: '',
    sede: '',
    fechaHechoInfractor: '',
    detallesDenuncia: '',
    tieneEvidencia: '',
    obtenerEvidencia: '',
    mantenerAnonimato: 'no',
    nombreAnonimo: '',
    apellidosAnonimo: '',
    cargoOtroAnonimo: '',
    mantenerContacto: 'no',
    correoElectronico: '',
    numeroTelefono: '',
  });



  const handleSubmit = (e) => {
    if (captcha.current.getValue()) {
      Swal.fire({
        icon: 'success',
        title: 'Mensaje enviado correctamente!',
        text: 'Muchas gracias por su realizar su denuncia',
      }).then(function () {
        window.location = "/"
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Debes realizar la verificacion de seguridad!',
      })
      e.preventDefault()
    }
  }

  const handleChange = (event) => {
    const { name, value, type } = event.target;


    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? (prevFormData[name].includes(value) ? prevFormData[name].filter(item => item !== value) : [...prevFormData[name], value]) : value,
    }))
  };

  const handleKeyPressSpecialCharacters = (e) => {
    const keyCode = e.which || e.keyCode;
    const isValidKey =
      (keyCode >= 48 && keyCode <= 57) || // Números del 0 al 9
      (keyCode >= 65 && keyCode <= 90) || // Letras mayúsculas A-Z
      (keyCode >= 97 && keyCode <= 122) || // Letras minúsculas a-z
      keyCode === 8 || keyCode === 9 || keyCode === 32;

    const forbiddenChars = ['{', '}', '/', '!', '[', ']', '(', ')'];
    const inputValue = String.fromCharCode(keyCode);
    const isCharForbidden = forbiddenChars.includes(inputValue);

    if (!isValidKey || isCharForbidden) {
      e.preventDefault()
    }
  };

  const captcha = useRef(null)

  const apiRma = process.env.REACT_APP_API_CONTACTO

  const [t] = useTranslation("global")

  return (
    <div>
      <img loading='lazy' src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody'></img>

      <form className="formulario-denuncia mt-3" onSubmit={handleSubmit} action={`${apiRma}/denuncia`} method="POST">
        <h2 className='mb-5'>{t("formDenuncia.titulo")}</h2>
        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.subtitulo-seleccione")}
        </label>
        <hr />
        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-1")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-1")}
        </div>

        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-2")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-2")}
        </div>
        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-3")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-3")}
        </div>
        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-4")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-4")}
        </div>
        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-5")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-5")}
        </div>
        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-6")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-6")}
        </div>
        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-7")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-7")}
        </div>
        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-8")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-8")}
        </div>
        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-9")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-9")}
        </div>
        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-10")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-10")}
        </div>
        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-11")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-11")}
        </div>
        <div>
          <input
            type="checkbox"
            name="tipoInfraccion"
            value={t("formDenuncia.checkbox-12")}
            onChange={handleChange}
          />
          {t("formDenuncia.checkbox-12")}
        </div>
        {formData.tipoInfraccion.includes('Otro') && (
          <div>
            <label style={{ textTransform: "uppercase" }}>
              {t("formDenuncia.opcion-otro")}
            </label>
            <input
              type="text"
              name="otroTipoInfraccion"
              value={formData.otroTipoInfraccion}
              onChange={handleChange}
            />
          </div>
        )}
        <hr />
        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.relacion-compañia")}
        </label>
        <input
          type="text"
          name="relacionCompania"
          value={formData.relacionCompania}
          onChange={handleChange}
          onKeyDown={handleKeyPressSpecialCharacters}
          required
        />
        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.pais")}
        </label>
        <input
          type="text"
          name="pais"
          value={formData.pais}
          onChange={handleChange}
          onKeyDown={handleKeyPressSpecialCharacters}
          required
        />
        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.ciudad")}
        </label>
        <input
          type="text"
          name="ciudad"
          value={formData.ciudad}
          onChange={handleChange}
          onKeyDown={handleKeyPressSpecialCharacters}
          required
        />
        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.estado-provincia")}
        </label>
        <input
          type="text"
          name="estadoProvincia"
          value={formData.estadoProvincia}
          onChange={handleChange}
          onKeyDown={handleKeyPressSpecialCharacters}
          required
        />
        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.sede")}
        </label>
        <input
          type="text"
          name="sede"
          value={formData.sede}
          onChange={handleChange}
          onKeyDown={handleKeyPressSpecialCharacters}
          required
        />
        <hr />

        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.indicar-fecha")}
        </label>
        <input
          type="date"
          name="fechaHechoInfractor"
          value={formData.fechaHechoInfractor}
          onChange={handleChange}
          required
        />

        <hr />


        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.describa-detalles")}
        </label>
        <textarea
          name="detallesDenuncia"
          value={formData.detallesDenuncia}
          onChange={handleChange}
          onKeyDown={handleKeyPressSpecialCharacters}
          required
        />
        <hr />
        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.evidencia")}
        </label>
        <div>
          <input
            type="text"
            name="tieneEvidencia"
            value={formData.tieneEvidencia}
            onChange={handleChange}
            onKeyDown={handleKeyPressSpecialCharacters}
            required
          />
        </div>

        <hr />
        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.obtener-evidencia")}
        </label>
        <input
          type="text"
          name="obtenerEvidencia"
          value={formData.obtenerEvidencia}
          onChange={handleChange}
          onKeyDown={handleKeyPressSpecialCharacters}
          required
        />
        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.datos-adicionales")}
        </label>
        <textarea
          type="text"
          name="datosAdicionales"
          value={formData.datosAdicionales}
          onChange={handleChange}
          onKeyDown={handleKeyPressSpecialCharacters}
          required
        />
        <hr />
        {/* <label>
          ¿CUÁL DE LAS SIGUIENTES OPCIONES DESCRIBE MEJOR SU RELACIÓN CON LA COMPAÑÍA?
        </label>
      
        <div>
          <input
            type="radio"
            name="relacionCompaniaTipo"
            value="EMPLEADO"
            checked={formData.relacionCompaniaTipo === 'EMPLEADO'}
            onChange={handleChange}
          />
          EMPLEADO
        </div>
        <div>
          <input
            type="radio"
            name="relacionCompaniaTipo"
            value="PROVEEDOR"
            checked={formData.relacionCompaniaTipo === 'PROVEEDOR'}
            onChange={handleChange}
          />
          PROVEEDOR
        </div>
        <div>
          <input
            type="radio"
            name="relacionCompaniaTipo"
            value="CLIENTE"
            checked={formData.relacionCompaniaTipo === 'CLIENTE'}
            onChange={handleChange}
          />
          CLIENTE
        </div>
        <hr/> */}
        <label style={{ textTransform: "uppercase" }}>
          {t("formDenuncia.anonimo")}
        </label>
        <div style={{ textTransform: "uppercase" }}>
          <input
            type="radio"
            name="mantenerAnonimato"
            value="SI"
            checked={formData.mantenerAnonimato === 'SI'}
            onChange={handleChange}
          />
          {t("formDenuncia.si")}
        </div>
        <div style={{ textTransform: "uppercase" }}>
          <input
            type="radio"
            name="mantenerAnonimato"
            value="NO"
            checked={formData.mantenerAnonimato === 'NO'}
            onChange={handleChange}
          />
          {t("formDenuncia.no")}
        </div>
        <hr />
        {formData.mantenerAnonimato === 'NO' && (
          <div>
            <label style={{ textTransform: "uppercase" }}>
              {t("formDenuncia.nombre")}
            </label>
            <input
              type="text"
              name="nombreAnonimo"
              value={formData.nombreAnonimo}
              onChange={handleChange}
              onKeyDown={handleKeyPressSpecialCharacters}
            />
            <label style={{ textTransform: "uppercase" }}>
              {t("formDenuncia.apellidos")}
            </label>
            <input
              type="text"
              name="apellidosAnonimo"
              value={formData.apellidosAnonimo}
              onChange={handleChange}
              onKeyDown={handleKeyPressSpecialCharacters}
            />
            <label style={{ textTransform: "uppercase" }}>
              {t("formDenuncia.especificar")}
            </label>
            <input
              type="text"
              name="cargoOtroAnonimo"
              value={formData.cargoOtroAnonimo}
              onChange={handleChange}
              onKeyDown={handleKeyPressSpecialCharacters}
            />
            <label style={{ textTransform: "uppercase" }}>
              {t("formDenuncia.proporcionar-email-num")}
            </label>
            <div style={{ textTransform: "uppercase" }}>
              <input
                type="radio"
                name="mantenerContacto"
                value="SI"
                checked={formData.mantenerContacto === 'SI'}
                onChange={handleChange}
              />
              {t("formDenuncia.si")}
            </div>
            <div style={{ textTransform: "uppercase" }}>
              <input
                type="radio"
                name="mantenerContacto"
                value="NO"
                checked={formData.mantenerContacto === 'NO'}
                onChange={handleChange}
              />
              {t("formDenuncia.no")}
            </div>
            {formData.mantenerContacto === 'SI' && (
              <div>
                <label style={{ textTransform: "uppercase" }}>
                  {t("formDenuncia.correo")}
                </label>
                <input
                  type="email"
                  name="correoElectronico"
                  value={formData.correoElectronico}
                  onChange={handleChange}
                />
                <label style={{ textTransform: "uppercase" }}>
                  {t("formDenuncia.numero")}
                </label>
                <input
                  type="number"
                  name="numeroTelefono"
                  value={formData.numeroTelefono}
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        )}

        <ReCAPTCHA
          className='mt-3 ml-3'
          ref={captcha}
          sitekey={data.sitekey}
        />
        <button type="submit">{t("formDenuncia.btn-enviar-denuncia")}</button>
      </form>

      <div className='mt-5'>
        <Accordion
          title={t("formDenuncia.politica-privacidad")}
          content={
            <p>
              {t("formDenuncia.politica-privacidad-1")} {data.name}<br />
              {t("formDenuncia.politica-privacidad-2")} {data.name}{t("formDenuncia.politica-privacidad-3")}
              {data.name}{t("formDenuncia.politica-privacidad-4")}<br />
              {t("formDenuncia.politica-privacidad-5")}
            </p>
          }
        />

        <Accordion
          title={t("formDenuncia.confidencialidad")}
          content={
            <p>
              {t("formDenuncia.confidencialidad-1")}<br />
              {t("formDenuncia.confidencialidad-2")}
            </p>
          }
        />

        <Accordion
          title={t("formDenuncia.acerca-de")}
          content={
            <p>
              {t("formDenuncia.acerca-de-1")}<br />
              {t("formDenuncia.acerca-de-2")}
            </p>
          }
        />
      </div>

    </div>
  );
};

export default FormularioDenuncia;
