import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SwalFire } from '../helpers/SwalFire.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './RegistroUsuario.css';
import { data } from "../../config/config.js"
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const apiContacto = process.env.REACT_APP_API_CONTACTO;
export const RegistroUsuario = () => {
	const [usuarioValido, setUsuarioValido] = useState(false);
	const captcha = useRef(null);
	const { register, handleSubmit, formState: { errors } } = useForm();

	const onChange = () => {
		if (captcha.current.getValue()) {
			console.log('El usuario no es un robot');
			setUsuarioValido(true);
		}
	}
	const navigate = useNavigate();
	const [loggin, setLoggin] = useState(false)

	const values = useState({
		nombre: '',
		email: '',
		razon_social: '',
		numero_cliente: null,
		telefono: null,
		cargo: '',
	});

	// ------------------------- Traigo informacion del navegador del usuario -----------------
	useEffect(() => {
		axios
			.get('https://api.ipify.org?format=json')
			.then((response) => {
				const ip = response.data.ip;
				const language = navigator.language || navigator.userLanguage;
				const userAgent = navigator.userAgent;
				const operatingSystem = getOperatingSystem(userAgent);
				document.getElementById('languageInput').value = language;
				document.getElementById('operatingSystemInput').value = operatingSystem;
				document.getElementById('ipInput').value = ip;
				localStorage.setItem('language', language);
				localStorage.setItem('operatingSystem', operatingSystem);
				localStorage.setItem('ip', ip);
				console.log(`Language: ${language}`);
				console.log(`Operating System: ${operatingSystem}`);
				console.log(`IP: ${ip}`);
			})
			.catch((error) => {
				console.log('Error al obtener la información del cliente:', error);
			});
	}, []);

	const swalAlert = (status) => {
		if (status !== 201) {
			console.log(status);
			SwalFire('Error interno', 'Intente mas tarde. Disculpe las molestias', 'error');
			return false
		}
		SwalFire('Formulario de registro procesado correctamente', 'Muchas gracias por su tiempo, nuestro staff verificara sus datos y en un plazo de 24hs recibirá sus credenciales de acceso', 'success');
		navigate('/');
	}

	// Submit registro de usuario 
	const handleSubmitRegistro = async (data) => {
		if (captcha.current.getValue()) {
			console.log('El usuario no es un robot');
		} else {
			console.log('Por favor acepta el captcha');
			return;
		}

		const language = navigator.language || navigator.userLanguage;
		const operatingSystem = getOperatingSystem(navigator.userAgent);
		const ipResponse = await axios.get('https://api.ipify.org?format=json');
		const ip = ipResponse.data.ip;

		const updatedValues = {
			...data,
			numero_cliente: Number(data.numero_cliente),
			language,
			operatingSystem,
			ip
		};

		try {
			Swal.fire({
				title: 'Procesando su solicitud',
				html: 'Espere por favor',
				allowEscapeKey: !loggin,
				allowOutsideClick: !loggin,
				didOpen: () => {
					Swal.showLoading(loggin);
				}
			});

			const response = await axios.post(`${apiContacto}/solicitud-usuario`, updatedValues);
			console.log(response);

			Swal.close();

			if (response.status === 201) {
				Swal.fire({
					title: t("formulario.formulario-enviado-tittle"),
					text: t("formulario.formulario-enviado-subtittle"),
					icon: 'success'
				}).then(() => navigate('/'));

			} else {
				swalAlert(response.status);
			}

			setLoggin(false);
		} catch (error) {
			console.error(error);
			const status = error.response ? error.response.status : 'Error desconocido';
			swalAlert(status);
		}
	};

	// Obtener datos del sistema operativo
	function getOperatingSystem(userAgent) {
		const platforms = {
			win: 'Windows',
			mac: 'Mac OS',
			linux: 'Linux',
			ios: 'iOS',
			android: 'Android'
		};

		if (userAgent.indexOf('Win') !== -1) {
			return platforms.win;
		} else if (userAgent.indexOf('Mac') !== -1) {
			return platforms.mac;
		} else if (userAgent.indexOf('Linux') !== -1) {
			return platforms.linux;
		} else if (userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1) {
			return platforms.ios;
		} else if (userAgent.indexOf('Android') !== -1) {
			return platforms.android;
		}

		return 'Desconocido';
	}

	const [t] = useTranslation("global")

	return (
		<div className="contenedor-registro">
			<form className='form-registro' onSubmit={handleSubmit(handleSubmitRegistro)}>
				<div className="form-group-registro">
					<label htmlFor="nombre">{t("formulario.label-1")}:</label>
					<input {...register('nombre', {
						required: {
							value: true,
							message: 'Campo nombre es requerido!'
						}, pattern: {
							value: /^[^@./{}[\]]+$/,
							message: 'No se permiten caracteres especiales en este campo!'
						},
					})} type="text" placeholder={t("formulario.placeholder-1")} />
					{errors.nombre && <p style={{ color: 'red', fontWeight: 'bold', textDecoration: 'underline' }}>{errors.nombre.message}</p>}
				</div>

				<div className="form-group-registro">
					<label htmlFor="email">Email ({t("formulario.user-adm")}):</label>
					<input {...register('email', {
						required: {
							value: true,
							message: 'Campo email es requerido!'
						}, pattern: {
							value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
							message: 'Formato de email no valido!'
						}
					})} type="email" autoComplete='off' placeholder={t("formulario.placeholder-2")} />
					{errors.email && <p style={{ color: 'red', fontWeight: 'bold', textDecoration: 'underline' }}>{errors.email.message}</p>}
				</div>

				<div className="form-group-registro">
					<label htmlFor="razon_social">{t("formulario.label-5")}:</label>
					<input {...register('razon_social', {
						required: {
							value: true,
							message: 'Campo razon social es requerido!'
						}, pattern: {
							value: /^[^@./{}[\]]+$/,
							message: 'No se permiten caracteres especiales en este campo!'
						},
					})} type="text" placeholder={t("formulario.placeholder-5")} />
					{errors.razon_social && <p style={{ color: 'red', fontWeight: 'bold', textDecoration: 'underline' }}>{errors.razon_social.message}</p>}
				</div>

				<div className="form-group-registro">
					<label htmlFor="numero_cliente">{t("formulario.label-6")}:</label>
					<input type="number" {...register('numero_cliente', {
						required: {
							value: true,
							message: 'Campo Numero de cliente es requerido!'
						},
						pattern: {
							value: /^[^@./{}[\]]+$/,
							message: 'No se permiten caracteres especiales en este campo!'
						},
						validate: {
							defaultValueZero: value => value !== undefined && value !== null || '0',
							maxLength: value => value.toString().length <= 5 || 'El número de cliente no debe contener más de 5 caracteres.',
							maxNumber: value => value <= 99999 || 'El número de cliente no puede ser mayor a 99999.'
						},
					})}
						placeholder={t("formulario.placeholder-6")}
						min={0}
						max={99999} />
					{errors.numero_cliente && <p style={{ color: 'red', fontWeight: 'bold', textDecoration: 'underline' }}>{errors.numero_cliente.message}</p>}
				</div>


				<div className="form-group-registro">
					<label htmlFor="telefono">{t("formulario.label-7")}:</label>
					<input {...register('telefono', {
						required: {
							value: true,
							message: 'Campo numero de telefono es requerido!'
						}, pattern: {
							value: /^[^@./{}[\]]+$/,
							message: 'No se permiten caracteres especiales en este campo!'
						},
						validate: {
							onlyNumbers: value => /^[0-9]+$/.test(value) || 'Este campo debe contener números!'
						}
					})} type="number" placeholder={t("formulario.placeholder-7")} />
					{errors.telefono && <p style={{ color: 'red', fontWeight: 'bold', textDecoration: 'underline' }}>{errors.telefono.message}</p>}
				</div>

				<div className="form-group-registro">
					<label htmlFor="cargo">{t("formulario.label-8")}</label>
					<input {...register('cargo', {
						required: {
							value: true,
							message: 'Campo cargo es requerido!'
						}, pattern: {
							value: /^[^@./{}[\]]+$/,
							message: 'No se permiten caracteres especiales en este campo!'
						},
					})} type="text" placeholder={t("formulario.placeholder-8")} />
					{errors.cargo && <p style={{ color: 'red', fontWeight: 'bold', textDecoration: 'underline' }}>{errors.cargo.message}</p>}
				</div>


				<input type="hidden" name="language" id="languageInput" value={values.language} />
				<input type="hidden" name="operatingSystem" id="operatingSystemInput" value={values.operatingSystem} />
				<input type="hidden" name="ip" id="ipInput" value={values.ip} />

				<div className='captchaCont'>
					<ReCAPTCHA
						onChange={onChange}
						ref={captcha}
						sitekey={data.sitekey}
					/>
				</div>

				{!usuarioValido ? (
					<div className="form-group-registro">
						<button disabled type="submit">{t("formulario.btn-enviar")}</button>
					</div>
				) : (
					<div className="form-group-registro">
						<button type="submit">{t("formulario.btn-enviar")}</button>
					</div>
				)}
			</form>
		</div>
	)
}


