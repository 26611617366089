/* eslint-disable react/prop-types */
import React from 'react';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

const country = process.env.REACT_APP_COUNTRY;
let montoPais = "";

if (country === "PY") {
    montoPais = "GS";
} else if (country === "GT") {
    montoPais = "Q";
} else {
    montoPais = "$";
}

// eslint-disable-next-line react/prop-types
export const Taxes = ({ taxPesos, taxDolares }) => {
    const [t] = useTranslation("global");

    const containerStyle = {
        textAlign: 'center',
        width: '100%',
        margin: '0 auto',
    };

    const headingStyle = {
        marginTop: '20px',
        fontWeight: 'bold',
        fontSize: '32px',
        color: '#475569'
    };

    const tableStyle = {
        width: '100%',
        marginBottom: '30px'
    };

    const thStyle = {
        textAlign: 'center',
        fontWeight: 'bold',
        width: '50%',
        fontSize: '25px',
        color: '#78716c'
    };

    const tdStyle = {
        textAlign: 'center',
        width: '50%',

    };

    return (
        <div style={containerStyle}>
            <h4 style={headingStyle}>{t("impuesto.monto-pais")} {montoPais}</h4>
            <Table style={tableStyle} bordered>
                <thead>
                    <tr>
                        <th style={thStyle}>{t("impuesto.concepto")}</th>
                        <th style={thStyle}>{t("impuesto.monto")}</th>
                    </tr>
                </thead>
                <tbody>
                    {taxPesos.map((item, index) => (
                        <tr key={index}>
                            <td style={tdStyle}>{Object.keys(item)}</td>
                            <td style={tdStyle}>{country === 'GT' ? 'Q' : '$'} {Object.values(item)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <h4 style={headingStyle}>{t("impuesto.monto-dolar")}</h4>
            <Table style={tableStyle} bordered>
                <thead>
                    <tr>
                        <th style={thStyle}>{t("impuesto.concepto")}</th>
                        <th style={thStyle}>{t("impuesto.monto")}</th>
                    </tr>
                </thead>
                <tbody>
                    {taxDolares.map((item, index) => (
                        <tr key={index}>
                            <td style={tdStyle}>{Object.keys(item)}</td>
                            <td style={tdStyle}>U$D {Object.values(item)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};
