import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { authContext } from '../components/Context/AuthContext';

const useOpenbox = () => {
    const { id } = useParams();
    const { token } = useContext(authContext);
    const apiProductos = process.env.REACT_APP_API_PRODUCTOS;
    const elementosPorPagina = 5;

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [load, setLoad] = useState(false);
    const [cantidadDePaginas, setCantidadDePaginas] = useState(0);

    const obtenerDatos = async (offset) => {
        try {
            setLoad(false);
            const response = await axios.get(`${apiProductos}/openbox?limit=${elementosPorPagina}&offset=${offset}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            });
            const openbox = response.data.articulos;

            localStorage.setItem('openbox', JSON.stringify(openbox));

            setData(prevData => [...prevData, ...openbox]);
            setCantidadDePaginas(Math.ceil(response.data.length / elementosPorPagina));

            setTimeout(() => {
                setLoad(true);
            }, 1000);
        } catch (error) {
            console.log(error);
            setLoad(true);
        }
    };


    useEffect(() => {
        if (id !== undefined) {
            setCurrentPage(1);
        }
        obtenerDatos();
    }, [id]);

    const handleNextPage = () => {
        const nextPage = currentPage + 1;
        const offset = elementosPorPagina * (nextPage - 1);

        if (nextPage <= cantidadDePaginas) {
            setCurrentPage(nextPage);
            obtenerDatos(offset);
        }
    };


    return { data, load, currentPage, cantidadDePaginas, handleNextPage, elementosPorPagina };
}

export default useOpenbox;
