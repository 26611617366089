import React from 'react'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { cartContext } from '../Context/CartContext'

// eslint-disable-next-line react/prop-types
const BtnCartSumar = ({ item }) => {

  const { addToCart } = useContext(cartContext)

  return (
    <>
      <div className='agregarUnidades'>
        <button className='sumar'
          onClick={() => {
            // eslint-disable-next-line react/prop-types
            if (item.contador < item.Stock) {
              addToCart(item, 1)
            }
            else {
              toast.error('El stock actual es insuficiente para agregar mas productos.', {
                autoClose: 400,
              });
              return false
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            style={{ width: '20px', height: '20px', color: 'red' }}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </button>
      </div>
    </>
  )
}

export default BtnCartSumar