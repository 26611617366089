import React, { useState } from 'react'
import "./ScrollButtonStyle.css"

export const ScrollButton = () => {

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1000) {
      setVisible(true)
    }
    else if (scrolled <= 1000) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <div className="noMuestra">
      <span onClick={scrollToTop} className="buttonScrollTop"
        style={{ display: visible ? 'inline' : 'none' }}>
        <img src={process.env.PUBLIC_URL + "/assets/flecha2.png"} alt="flecha" loading='lazy' />
      </span>
    </div>

  )
}
