/* eslint-disable react/prop-types */
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export const SpinnerGif = ({ text }) => {
    return (
        <div className='divSpinner'>
            <Spinner animation="border" role="status" id="spinner">
            </Spinner>
            <span>{text}</span>
        </div>
    )
}