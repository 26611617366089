import React from 'react';
import './FlexOfertas.css';
import { CiDiscount1 } from "react-icons/ci";
import { FaBoxOpen } from "react-icons/fa";
import { useNavigate } from 'react-router';

const FlexOpenbox = () => {
    const navigate = useNavigate()

    return (
        <div className="flex-ofertas-container" style={{
            maxWidth: '1500px',
            display: 'flex',
            justifyContent: 'space-around'
        }}>
            <div className="text">
                <h2 style={{ fontWeight: 'bold', color: '#0d1821' }}>OPENBOX</h2>
                <p style={{ fontWeight: 'bold', color: '#5e6472' }}>¡Encontrá precios increíbles cada día!</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className='flex-ofertas-icons selected'>
                <CiDiscount1 size={'4em'} style={{ marginBottom: '3px' }} color="#0077b6" onClick={() => navigate('/ofertas')} />
                <p style={{ fontWeight: 'bold', color: '#5e6472' }}>Ofertas</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className='flex-ofertas-icons'>
                <FaBoxOpen size={'4em'} style={{ marginBottom: '3px', }} color="red" onClick={() => navigate('/openbox')} />
                <p style={{ fontWeight: 'bold', color: '#5e6472', marginBottom: '3px' }}>Open box</p>
                <div className="divider"></div>
            </div>
        </div>
    );
};

export default FlexOpenbox;