import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LocalStorage } from '../Data/LocalStorage'
import { Bounce, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import './ButtonTranslate.css'
import Dropdown from 'react-bootstrap/Dropdown';

const ButtonTranslate = () => {

    const [t, i18n] = useTranslation("global")
    const [trasnlatedTo, setTranslatedTo] = LocalStorage("trasnlatedTo", null)

    useEffect(() => {
        trasnlatedTo && i18n.changeLanguage(trasnlatedTo);
    }, [i18n, trasnlatedTo]);

    const notify = (lng) => toast.success(
        lng === 'es'
            ? "Cambiando a español"
            : "Switched to english", {
        position: "top-center",
        autoClose: 200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    const handleCLick = (lng) => {
        setTranslatedTo(lng)
        i18n.changeLanguage(lng);
        notify(lng);
    }

    return (

        <div className="btnLogueado2">
            <Dropdown align={{ lg: 'end' }} id="dropdown-menu-align-responsive-1">
                <Dropdown.Toggle style={{ backgroundColor: '#212529', right: 0, borderColor: '#212529' }} id="dropdown-basic">
                    <i className="fa fa-globe" style={{ color: '#81c3d7' }} aria-hidden="true"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ backgroundColor: 'black', right: 0 }}>
                    <Dropdown.Item onClick={() => handleCLick("es")} style={{ display: 'flex', justifyContent: 'space-around' }} >
                        <p>{t("idioma.español")}</p>
                        <img width={30} height={20} src={
                            process.env.PUBLIC_URL +
                            "/assets/Banderas/spain.png"
                        } alt='ES' />

                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleCLick("en")} style={{ display: 'flex', justifyContent: 'space-around' }} >
                        <p>{t("idioma.inglés")}</p>
                        <img width={30} height={20} src={
                            process.env.PUBLIC_URL +
                            "/assets/Banderas/estadosunidos.png"
                        } alt='EN' />
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default ButtonTranslate