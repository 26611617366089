/* eslint-disable react/prop-types */
import React from 'react';
import './CardOferta.css';
import { useTranslation } from 'react-i18next';

const CardOferta = (props) => {

    const [t] = useTranslation("global")

    return (
        <div className="oferta-card">
            <div className="oferta-tag">{props.icon ? props.icon : ''}
                {' '}{props.Oferta ? props.Oferta : 'En oferta'}</div>
            <div className="oferta-card-border-top"></div>

            <div className="oferta-card-content">
                <div>
                    <img src={props.imagen} alt="" className="oferta-card-img" />
                </div>

                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{props.nombre}</span>
                    <p className="oferta-job" style={{ fontSize: '14px' }}>{props.descripcion}</p>
                    {props.precio && <p className="oferta-job-precio">USD {props.precio}</p>}
                    {props.precio && <p className="oferta-job-precio-real">Stock: {props.stock}</p>}
                </div>
            </div>

            <div className="oferta-card-button">
                <button>{t("ofertas.ver")}</button>
            </div>
        </div>
    );
};

export default CardOferta;
