import axios from 'axios';

const API_URL = process.env.REACT_APP_API_CLIENTE;

export const getDNIService = async (token) => {
    try {
        if (!token) {
            console.error('Token no disponible');
            throw new Error('No autorizado');
        }

        const response = await axios.get(`${API_URL}/retira/dnis`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener los datos:', error);
        throw error;
    }
};


export const updateDNIService = async (id, data, token) => {
    try {
        if (!token) {
            console.error('Token no disponible');
            throw new Error('No autorizado');
        }

        const response = await axios.put(`${API_URL}/retira/dni/${id}`, data, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        console.error('Error al actualizar el autorizado:', error);
        throw error;
    }
};

export const deleteDNIService = async (id, token) => {
    try {
        if (!token) {
            console.error('Token no disponible');
            throw new Error('No autorizado');
        }

        const response = await axios.delete(`${API_URL}/retira/dni/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        console.error('Error al eliminar el DNI:', error);
        throw error;
    }
};


