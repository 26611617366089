import React from 'react';
import { Container } from 'react-bootstrap';
import './NuestraEmpresa.css';
import { empleados } from '../../config/config';
import { useTranslation } from 'react-i18next';

export const NuestraEmpresa = () => {
	const country = process.env.REACT_APP_COUNTRY;

	const empleadosOrdenados = empleados.sort((a, b) => { //ORDENO ARRAY POR JERARQUIAS
		if (country !== 'USA') {
			const orden = {
				'dirección': 1,
				'country-manager': 2,
				'gerencia': 3,
				'product-manager': 4,
				'ventas': 5,
				'administracion': 6,
			};
			// Si la categoría no está en el objeto de orden, asignar un valor alto para colocarla al final
			const ordenA = orden[a.category] || Number.MAX_SAFE_INTEGER;
			const ordenB = orden[b.category] || Number.MAX_SAFE_INTEGER;

			return ordenA - ordenB;
		} else {
			const orden = {
				'country-manager': 1,
				'manager': 2,
				'product-manager': 3,
				'sales': 4,
				'administration': 5,
			};

			const ordenA = orden[a.category] || Number.MAX_SAFE_INTEGER;
			const ordenB = orden[b.category] || Number.MAX_SAFE_INTEGER;

			return ordenA - ordenB;
		}
	});

	const empleadosPorCategoria = empleadosOrdenados.reduce((acc, persona) => { // Agrupa Empleados por categoría
		const categoria = persona.category;

		if (!acc[categoria]) {
			acc[categoria] = [];
		}

		acc[categoria].push(persona);

		return acc;
	}, {});

	const [t] = useTranslation("global")

	return (
		<>
			<Container className='my-5'>
				<div className="heading-content">
					<h2 className='mb-5'>{t("nuestraEmpresa.quienes-somos")}</h2>
					<p className='text-content'>
						{t("nuestraEmpresa.quienes-somos-parrafo")}
					</p>
				</div>
				<div className="staff my-5">
					<h2>{t("nuestraEmpresa.nuestro-staff")}</h2>
					<div className="cards-grid" style={{ flexWrap: 'wrap' }}>
						{Object.entries(empleadosPorCategoria).map(([categoria, empleadosCategoria], index) => (
							<div key={categoria} className='categorias-container' style={{ width: '100%', textAlign: 'center' }}>
								{index !== 0 && <hr className='text-dark' style={{ width: '50%', textAlign: 'center', background: 'black' }} />}
								<h2 className='category'>{t(`${categoria}`)}</h2>
								<div className="cards-grid">
									{empleadosCategoria.map((persona) => (
										<div
											key={persona.id}

											style={{ overflow: 'visible' }}
										>
											<div
												key={persona.id}
												className="cards-empleados cards-empleados-shadow"
												style={{ overflow: 'visible' }}
											>
												<div className="cards-empleados-image-container">
													<a href={`mailto:${persona.correo}`} style={{ cursor: "pointer" }}>
														<img
															src={persona.img}
															alt="Imagen"
															className="cards-empleados-image"
														/>
													</a>
												</div>
												<div className="cards-empleados-content">
													<h2 style={{ fontWeight: 'bold', textAlign: 'center' }}>
														{persona.nombre}
													</h2>
													<p style={{ fontWeight: 'bold' }}>
														{persona.correo}
													</p>
													<p>{persona.movil}</p>
													<hr />
													<p style={{ fontWeight: 'bold' }}>
														{persona.cargo}
													</p>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
			</Container>
		</>
	);
};
