/* eslint-disable react/prop-types */
import React from 'react';
import './CardMarcas.css';
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import AddItem from "../Carrito/AddItem";
import { globalContext } from '../Context/GlobalContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'; // Importar styled-components

const StyledCard = styled(Card)`
    width: 16rem; /* Ancho fijo para la tarjeta */
    border: none;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para dar profundidad */
	  -webkit-line-clamp: 2; /* Limitar a 2 líneas */
`;

const Title = styled(Card.Title)`
    display: -webkit-box; /* Usar flexbox con WebKit */
    -webkit-box-orient: vertical; /* Orientación vertical */
    -webkit-line-clamp: 2; /* Limitar a 2 líneas */
    overflow: hidden; /* Ocultar el desbordamiento */
    text-overflow: ellipsis; /* Mostrar "..." al final si hay más texto */
    cursor: pointer; /* Cambiar el cursor al pasar sobre el título */
    margin-bottom: 10px; /* Espacio inferior para separar del contenido */
    min-height: 47px; /* Altura mínima para evitar cortes en la línea inferior */
	-webkit-line-clamp: 2 !important; /* Limitar a 2 líneas */
`;

export default function CardMarcas(props) {
	const pais = process.env.REACT_APP_COUNTRY;
	const [t] = useTranslation("global");

	let img = process.env.PUBLIC_URL + "/assets/sinimagen.gif";
	const navigate = useNavigate();
	const articulosXMarca = props.articulosXMarca;
	const { grid, handleDisplayList } = useContext(globalContext);

	const irADetalles = (param, id) => {
		const id_replaced = id.replace(/ç/g, '');
		navigate(`/detalle?sku=${id_replaced}`, {
			state: {
				param,
				id
			}
		});
	}

	return (
		<>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Row>
					{articulosXMarca.map((articulo, idx) => (
						<Col key={idx} style={{ display: "flex", justifyContent: "center" }}>
							<div className='cards-cont' style={{ display: "flex" }}>
								<StyledCard>
									<div style={{ objectFit: "cover", height: "275px", display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Card.Img
											variant="top"
											src={
												articulo.Imagenes
													? process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(articulo.Imagenes?.split(',')[0])}`
													: img
											}
											style={{ maxWidth: "fit-content" }}
											alt={'solution box'}
										/>
									</div>
									<Card.Body>
										<Title 
                                            className='nombre-producto-card' 
                                            onClick={() => irADetalles(articulo, articulo.Alias)}
                                        >
                                            {articulo.Nombre}
                                        </Title>
                                        
										<Card.Text className='textoSKU' style={{ textAlign: 'left' }}>
                                            <b>SKU:</b> {articulo.Alias}
                                        </Card.Text>
                                        
										<div>
											<Card.Text className='m-0 textoPrecio'>
												{articulo.Precio === undefined ? '' : 'U$D: ' + articulo.Precio}
											</Card.Text>
											<Card.Text className='textoStock'>
												{articulo.Precio === undefined ? '' : 'Stock: ' + articulo.Stock}
											</Card.Text>
										</div>

										{pais === "PA" ? (
											<p className='precios-sin-iva-card'>
												{articulo.Precio === undefined ? '' : '* Los precios no incluyen ITBMS'}
											</p>
										) : pais === "USA" ? (
											null
										) : (
											<p className='precios-sin-iva-card'>
												{articulo.Precio === undefined ? '' : '* Los precios no incluyen IVA'}
											</p>
										)}

										<div className={`logged-buttons ${grid && 'flexGrid'}`}>
											<Button
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '0.5rem',
													padding: '0.25rem 0.5rem',
													fontSize: '0.75rem',
													justifyContent: 'center',
													minWidth: '120px',
												}}
												variant="outline-danger"
												onClick={() => {
													irADetalles(articulo, articulo.Alias);
													handleDisplayList();
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth={1.5}
													stroke="currentColor"
													style={{ width: '14px', height: '14px' }}
												>
													<path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
													<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
												</svg>

												{t("producto.ver-producto")}
											</Button>

											<br />
											
											{articulo.Precio !== undefined && (
												<div className="ml-0">
													<AddItem producto={articulo} />
												</div>
											)}
									    </div>
								    </Card.Body>
							        </StyledCard>
							    </div>
						    </Col>
					       ))}
				       </Row>
			       </div>
		       </>
           );
       }
