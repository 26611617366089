import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row, Pagination, Col, Button } from 'react-bootstrap'
import axios from 'axios'
import CardMarcas from "../CardMarcas/CardMarcas"
import Tables from '../Tables/Tables'
import { ImagenesMarcas } from './ImagenesMarcas'
import { BsFillGrid1X2Fill } from 'react-icons/bs'
import { FaThList } from 'react-icons/fa'
import "./CardStyle.css"
import { authContext } from '../Context/AuthContext'
import { globalContext } from '../Context/GlobalContext'
import { SpinnerMarcas } from '../helpers/SpinnerMarcas'
import { useTranslation } from 'react-i18next'

const apiProductos = process.env.REACT_APP_API_PRODUCTOS

export const ApiMap = () => {

  const { id } = useParams();
  const [idFound, setIdFound] = useState();
  const { token } = useContext(authContext);
  const { colorTheme, handleDisplayGrid, handleDisplayList, grid } = useContext(globalContext);
  const [articulosXMarca, setArticulosXMarca] = useState([]);
  const [largoTotal, setLargoTotal] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [load, setLoad] = useState(false);
  const elementosPorPagina = 12;
  const cantidadDePaginas = Math.ceil(largoTotal / elementosPorPagina);
  const country = process.env.REACT_APP_COUNTRY
  const [t] = useTranslation("global")


  const [min, setMin] = useState({});
  const [max, setMax] = useState({});
  const [sentido, setSentido] = useState({});
  const [filter, setFilter] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showOnlyStock, setShowOnlyStock] = useState(false);



  const borrarCampos = () => {
    setMin({});
    setMax({});
    setSentido({});
    const idValue = document.getElementById('sentido');
    if (!idValue) return false
    idValue.selectedIndex = '';
  }

  const obtenerDatos = async () => {
    try {
      setLoad(false);
      if (country === "USA") {
        const stockFilter = showOnlyStock ? 1 : 0;

        const response = await axios.get(`${apiProductos}/info/marca/${id}?Stock=${stockFilter}&limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        });

        console.log("USA response", response);
        setArticulosXMarca(response.data.articulos);
        setLargoTotal(response.data.length);

      } else {
        const response = await axios.get(`${apiProductos}/info/marca/${id}?limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        });

        console.log(response);
        setArticulosXMarca(response.data.articulos);
        setLargoTotal(response.data.length);
      }

      setTimeout(() => {
        setLoad(true);
      }, 1000);

      setIdFound(id);

    } catch (error) {
      console.log(error);
      setLoad(true);
    }
  };

  const filtrarPrecios = async () => {
    if (!filter) setCurrentPage(1);
    setFilter(true);

    try {
      let response;
      const stockFilter = showOnlyStock ? 1 : 0;

      if (country === "USA") {
        // Filtro de precios y stock para USA
        response = await axios.get(`${apiProductos}/info/marca/${id}?minPrice=${min.minPrice}&maxPrice=${max.maxPrice}&sentido=${sentido.sentido}&Stock=${stockFilter}&limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        });
      } else {
        // Filtro de precios sin stock para el resto de sucursales por ahora
        response = await axios.get(`${apiProductos}/info/marca/${id}?minPrice=${min.minPrice}&maxPrice=${max.maxPrice}&sentido=${sentido.sentido}&limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        });
      }

      console.log(response);
      setArticulosXMarca(response.data.articulos);
      setLargoTotal(response.data.length);
      setLoad(true);
      setIdFound(id);

    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    if (id !== idFound) {
      setCurrentPage(1)
    }
    if (filter && id === idFound) filtrarPrecios()
    else {
      setFilter(false)
      obtenerDatos();
      borrarCampos();
    }
  }, [id, currentPage, showOnlyStock]);

  useEffect(() => {
    const isMobileOnLoad = window.innerWidth < 670;
    setIsMobile(isMobileOnLoad);
    const handleResize = () => {
      setIsMobile(window.innerWidth < 670);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const paginas = [];
  function renderPagination() {
    for (let i = 1; i <= cantidadDePaginas; i++) {
      paginas.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
          {i}
        </Pagination.Item>
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className={`api-map-container bg-${colorTheme}`}>
      <ImagenesMarcas id={id} />
      <Container fluid className='py-5' style={{ width: "100%" }}>
        {(country === "USA") && (
          <div className="display-select-container">
            <Col
              className={`d-flex justify-content-${isMobile ? 'center' : 'center'} text-${colorTheme === 'dark' ? 'light' : 'dark'
                }`}
            >
              <div className="row">
                <div className="span6" style={{ width: isMobile ? '100%' : '115px' }}>
                  <div className="mycontent-left text-center">
                    <Button
                      variant="outline-danger"
                      onClick={() => setShowOnlyStock(!showOnlyStock)}
                      style={{
                        transition: 'background-color 0.3s, color 0.3s',
                        borderRadius: '50px',
                        padding: '10px 20px',
                        width: isMobile ? '100%' : '200px',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#dc3545';
                        e.currentTarget.style.color = 'white';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.style.color = '#dc3545';
                      }}
                    >
                      {showOnlyStock
                        ? t('stockButton.mostrar-todo')
                        : t('stockButton.mostrar-stock')}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </div>

        )}

        {!isMobile && (
          <div className="display-select-container">
            <Col className={`d-flex justify-content-end text-${colorTheme === 'dark' ? 'light' : 'dark'}`}>
              <div className="row">
                <div className="span6" style={{ width: "115px" }}>
                  <div className="mycontent-left text-center">
                    <h6 className="text-uppercase">{t("producto.ver-como")}</h6>
                    <div className="d-flex flex-col justify-content-around gap-1">
                      <div className="d-flex flex-row align-items-center svgGrilla" onClick={handleDisplayGrid}>
                        <BsFillGrid1X2Fill size={"1.5em"} />
                      </div>
                      <div className="d-flex flex-row align-items-center svgGrilla" onClick={handleDisplayList}>
                        <FaThList size={"1.5em"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        )}


        {!load
          ? <SpinnerMarcas text={'Cargando marca'} />
          :
          <Row className='mt-2' style={{ justifyContent: "center" }}>

            <Col xs={12} md={12} sm={6} xl={9} lg={12} style={{ display: "flex", justifyContent: "center" }}>
              {articulosXMarca.length <= 0 ? <h3>{t("producto.no-existe")}</h3> : isMobile ?
                <div className="taleShadow bg-light card-container">
                  <CardMarcas articulosXMarca={articulosXMarca} style={{ display: "flex", justifyContent: "center" }} />
                  <Pagination>{paginas}</Pagination>
                  <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                  {renderPagination()}
                </div>
                :
                grid ?
                  <div className="taleShadow bg-light card-container">
                    <CardMarcas articulosXMarca={articulosXMarca} style={{ display: "flex", justifyContent: "center" }} />
                    <Pagination>{paginas}</Pagination>
                    <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                    {renderPagination()}
                  </div>
                  :
                  <div className="taleShadow bg-light">
                    <Tables articulosXMarca={articulosXMarca} header={["", ""]} />
                    <Pagination>{paginas}</Pagination>
                    <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                    {renderPagination()}
                  </div>
              }
            </Col>
          </Row>
        }
      </Container>
    </div>
  )
}
