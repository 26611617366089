import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Modal, Table } from "react-bootstrap";
import { MdOutlineDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { globalContext } from "../../Context/GlobalContext";
import { RxUpdate } from "react-icons/rx";
import { deleteDNIService, updateDNIService } from "../../../services/DNI_Feature";
import { authContext } from "../../Context/AuthContext";
import useFetchAuthorizedPerson from "../../../utilities/hooks/useFetchAuthorizedPerson";


const DNIList = () => {
    const { authorizedPerson, setAuthorizedPerson } = useContext(globalContext);
    const { token } = useContext(authContext)
    const [selectedImages, setSelectedImages] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const { fetchAuthorizedPerson } = useFetchAuthorizedPerson()

    // Gestiono los modales 
    const handleOpenDeleteModal = (file) => {
        setFileToDelete(file);
        setShowDeleteModal(true);
    };


    const handleOpenModalConfirm = (file) => {
        setSelectedFile(file);
        setShowModal(true);
    };

    // Gestiono el visor de imagenes
    const handleImageClick = (frontSrc, backSrc) => {
        setSelectedImages({ front: frontSrc, back: backSrc });
    };

    const closeViewer = () => {
        setSelectedImages(null);
    };


    const updateAuthorizedPerson = async (id, updatedData) => {
        try {
            await updateDNIService(id, updatedData, token);
            const updatedAuthorizedPerson = authorizedPerson.map(person =>
                person.id === id ? { ...person, ...updatedData } : person
            );
            setAuthorizedPerson(updatedAuthorizedPerson);
            await fetchAuthorizedPerson();

            toast.success("Autorizado actualizado correctamente", {
                position: "bottom-right"
            });
        } catch (error) {
            console.error('Error al actualizar el autorizado:', error);
            toast.error("Error al actualizar el autorizado", {
                position: "bottom-right"
            });
        }
    };

    const deleteAuthorizedPerson = async (id, index) => {
        try {
            await deleteDNIService(id, token);
            const updatedAuthorizedPerson = authorizedPerson.filter((_, i) => i !== index);
            setAuthorizedPerson(updatedAuthorizedPerson);
            await fetchAuthorizedPerson();

            toast.success("Documento eliminado correctamente", {
                position: "bottom-right",
            });
        } catch (error) {
            toast.error("Error al eliminar el documento", {
                position: "bottom-right",
            });
        }
    };

    useEffect(() => {
        if (!authorizedPerson || authorizedPerson.length === 0) {
            fetchAuthorizedPerson();
        }
    }, []);


    // Confirmacion para autorizar una persona
    const handleConfirmAuthorization = async () => {
        try {
            if (selectedFile) {
                const updatedData = { autorizado: "true" };
                await updateAuthorizedPerson(selectedFile.id, updatedData);
                setShowModal(false);
                setSelectedFile(null);
            }
        } catch (error) {
            toast.error("Error al autorizar el documento", {
                position: "bottom-right",
            });
        }
    };

    // Ordeno la lista para que se muestre primero autorizado en true
    const sortedAuthorizedPerson = authorizedPerson
        .slice()
        .sort((a, b) => (b.autorizado ? 1 : 0) - (a.autorizado ? 1 : 0));

    // Eliminar una persona autorizada
    const handleDelete = async () => {
        if (fileToDelete) {
            try {
                await deleteAuthorizedPerson(fileToDelete.id);
                setShowDeleteModal(false);
                setFileToDelete(null);
            } catch (error) {
                toast.error("Error al eliminar el documento.");
            }
        }
    };

    return (
        <>
            <div
                style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#f9f9f9",
                    padding: "20px",
                }}
            >
                {authorizedPerson && authorizedPerson.length > 0 ? (
                    <Container style={{ textAlign: "center", maxWidth: "1200px" }}>
                        <h4>Documentos Cargados</h4>
                        <Table
                            striped
                            bordered
                            hover
                            style={{ marginTop: "30px", width: "100%" }}
                        >
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: "#64748b", color: "white" }}>Nombre Completo</th>
                                    <th style={{ backgroundColor: "#64748b", color: "white" }}>Número de Documento</th>
                                    <th style={{ backgroundColor: "#64748b", color: "white" }}>Fecha de Carga</th>
                                    <th style={{ backgroundColor: "#64748b", color: "white" }}>Imágenes</th>
                                    <th style={{ backgroundColor: "#64748b", color: "white" }}>Operaciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedAuthorizedPerson.map((file, index) => (
                                    <tr key={index}>
                                        <td>{`${file.nombre} ${file.apellido}`}</td>
                                        <td>{file.nroDocumento}</td>
                                        <td>{new Date(file.createdAt).toLocaleString()}</td>
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <img
                                                    src={`data:image/jpeg;base64,${file.frenteFile}`}
                                                    alt="Frente"
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        objectFit: "cover",
                                                        marginRight: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => handleImageClick(`data:image/jpeg;base64,${file.frenteFile}`, `data:image/jpeg;base64,${file.dorsoFile}`)}
                                                />
                                                <img
                                                    src={`data:image/jpeg;base64,${file.dorsoFile}`}
                                                    alt="Dorso"
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        objectFit: "cover",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => handleImageClick(`data:image/jpeg;base64,${file.frenteFile}`, `data:image/jpeg;base64,${file.dorsoFile}`)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    onClick={() => handleOpenModalConfirm(file)}
                                                    style={{
                                                        padding: '10px 20px',
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "6px",
                                                        marginRight: '6px',
                                                        width: '120px',
                                                    }}
                                                >
                                                    <RxUpdate size={15} />
                                                    Actualizar
                                                </Button>

                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={() => handleOpenDeleteModal(file)}
                                                    style={{
                                                        padding: '10px 20px',
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "6px",
                                                        width: '120px',
                                                    }}
                                                >
                                                    <MdOutlineDelete size={20} />
                                                    Eliminar
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Container>
                ) : (
                    <div style={{ textAlign: "center", padding: "40px" }}>
                        <h3>Aún no tienes documentos cargados.</h3>
                    </div>
                )}
            </div>

            {/* Modal de Confirmación de Autorización */}
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered  // Esto centra el modal en la pantalla
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirmación de Autorización</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Estás seguro de que quieres autorizar este documento?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleConfirmAuthorization}>
                        Autorizar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Confirmación de Eliminación */}
            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirmación de Eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Estás seguro de que deseas eliminar este documento?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Visor de imágenes */}
            {selectedImages && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 9999,
                        overflow: "hidden",
                    }}
                    onClick={closeViewer}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "20px",
                        }}
                    >
                        <div
                            style={{
                                width: "400px",
                                height: "300px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "8px",
                                backgroundColor: "white",
                                padding: "10px",
                            }}
                        >
                            <img
                                src={selectedImages.front}
                                alt="Frente"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    objectFit: "contain",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                width: "400px",
                                height: "300px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "8px",
                                backgroundColor: "white",
                                padding: "10px",
                            }}
                        >
                            <img
                                src={selectedImages.back}
                                alt="Dorso"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    objectFit: "contain",
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DNIList;
