/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import './Checkout.css';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { TbTruckDelivery } from "react-icons/tb";
import { globalContext } from '../Context/GlobalContext';
import { getTiposDeEntrega } from '../../services/CondicionesDePagoYEntrega/getTiposDeEntrega';
import UploadDNIModal from '../shared/UploadDNI/UploadDNIModal';
import { GoPerson } from "react-icons/go";
import { MdFileUpload } from "react-icons/md";
import { updateDNIService } from '../../services/DNI_Feature';
import { toast } from 'react-toastify';
import { authContext } from '../Context/AuthContext';
import useFetchAuthorizedPerson from '../../utilities/hooks/useFetchAuthorizedPerson';

export const Facturacion = ({
  user,
  enviarOrden,
  handleActualizarEntrega,
}) => {
  const {
    info,
    tiposDeEntrega,
    setTiposDeEntrega,
    infoInputs,
    handleEntregaChange,
    defaultValue,
    authorizedPerson,
    setAuthorizedPerson,
    selectedAuthorizedPerson,
    setSelectedAuthorizedPerson } = useContext(globalContext);
  const { token } = useContext(authContext)

  const [t] = useTranslation("global");

  const [showModal, setShowModal] = useState(false);
  const { fetchAuthorizedPerson } = useFetchAuthorizedPerson()

  const updateAuthorizedPerson = async (id, updatedData) => {
    try {
      await updateDNIService(id, updatedData, token);
      const updatedAuthorizedPerson = authorizedPerson.map(person =>
        person.id === id ? { ...person, ...updatedData } : person
      );
      setAuthorizedPerson(updatedAuthorizedPerson);
      await fetchAuthorizedPerson();

      toast.success("Autorizado actualizado correctamente", {
        position: "bottom-right"
      });
    } catch (error) {
      console.error('Error al actualizar el autorizado:', error);
      toast.error("Error al actualizar el autorizado", {
        position: "bottom-right"
      });
    }
  };


  const country = process.env.REACT_APP_COUNTRY;
  let rucPais = country === "PY" ? "RUC" : "DNI/CUIL";

  useEffect(() => {
    infoInputs({ target: { name: 'entrega', value: defaultValue } });
  }, [defaultValue]);

  const fetchTiposDeEntrega = async () => {
    const storedTiposDeEntrega = localStorage.getItem('tiposDeEntrega');
    if (storedTiposDeEntrega) {
      setTiposDeEntrega(JSON.parse(storedTiposDeEntrega));
    } else {
      try {
        const data = await getTiposDeEntrega();
        setTiposDeEntrega(data);
        localStorage.setItem('tiposDeEntrega', JSON.stringify(data));
      } catch (error) {
        console.log(error)
      }
    }
  };

  useEffect(() => {
    fetchTiposDeEntrega();
  }, []);

  useEffect(() => {
    handleActualizarEntrega();
  }, [info.entrega, info.pago]);

  // Handler para el cambio en el selector
  // eslint-disable-next-line no-unused-vars
  const handleRetiraChange = async (event) => {
    const { value } = event.target;
    const selected = authorizedPerson.find(person => person.nombre === value);
    setSelectedAuthorizedPerson(selected || null);

    if (selected) {
      const updatedData = { autorizado: "true" };
      await updateAuthorizedPerson(selected.id, updatedData);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // El autorizado seleccuionado es la persona que se selecciono en el select para que pueda hacer el retiro 

  useEffect(() => {
    if (selectedAuthorizedPerson) {
      console.log("Autorizado seleccionado:", selectedAuthorizedPerson);
    }
  }, [selectedAuthorizedPerson]);

  // La solicitud la depsego solo en Argentina
  useEffect(() => {
    if (info.entrega === "RETIRA" && country === "ARG") {
      if (!authorizedPerson || authorizedPerson.length === 0) {
        fetchAuthorizedPerson();
      }
    }
  }, [info.entrega]); // Lo ejecuto cuando cambie info.entrega para evirtar re renders



  return (
    <>
      <div>
        <form onSubmit={enviarOrden}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div style={{ flex: '1', marginRight: '20px' }}>
              <h3 style={{ color: '#475569' }}>{t("facturacion.datos")}</h3>
              <h6 style={{ color: '#9ca3af' }}><b>{t("facturacion.nombre")}:</b> {user.Nombre}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{t("facturacion.apellido")}: </b>{user.Apellido}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{t("formulario.label-2")}: </b>{user.Email}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{rucPais}: </b>{user.Cuit}</h6>
            </div>

            <div style={{ flex: '1' }}>
              <Form.Group controlId='entrega'>
                <Form.Label style={{ fontWeight: 'bold', fontSize: '1.25rem', color: '#475569' }}>
                  <TbTruckDelivery size={'2.5em'} style={{ marginRight: '12px', color: 'red' }} />
                  {t("facturacion.opciones-entrega")}
                </Form.Label>
                <Form.Control
                  as='select'
                  value={info.entrega}
                  name='entrega'
                  onChange={handleEntregaChange}
                >
                  <option value='Retira personalmente'>Seleccione...</option>
                  {tiposDeEntrega
                    .filter((entrega) => !(country === "CR" && entrega.Descripcion === "ENTREGA"))
                    .map((entrega) => (
                      <option key={entrega.Codigo} value={entrega.Descripcion}>
                        {entrega.Descripcion}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div style={{ flex: '1', marginRight: '20px' }}>
              <h3 style={{ color: '#475569' }}>{t("facturacion.direccion-declarada")}</h3>
              <h6 style={{ color: '#9ca3af' }}>{t("facturacion.podra-coordinar")}</h6>
              <br />
              <h6 style={{ color: '#9ca3af' }}><b>{t("formulario.direccion")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Domicilio : user.Domicilio_facturacion.Domicilio}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{t("facturacion.codigo-postal")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Codigo_postal : user.Domicilio_facturacion.Codigo_postal || 'No informado'}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{t("facturacion.localidad")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Localidad : user.Domicilio_facturacion.Localidad}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{t("facturacion.pais")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Pais : user.Domicilio_facturacion.Pais}</h6>
            </div>

            {info.entrega === "RETIRA" && country === "ARG" && (
              <div style={{ flex: '1' }}>
                <Form.Label style={{ fontWeight: 'bold', fontSize: '1.25rem', color: '#475569' }}>
                  <GoPerson size={'2.5em'} style={{ marginRight: '12px', color: 'red' }} />
                  Seleccione autorizado a retirar
                </Form.Label>
                <Form.Control as="select" onChange={handleRetiraChange}>
                  <option>Seleccione...</option>
                  {authorizedPerson.length === 0 ? null : (
                    authorizedPerson.map((file, index) => (
                      <option key={index} value={file.nombre}>
                        {file.nombre} {" "} {file.apellido}, DNI: {file.nroDocumento}
                      </option>
                    ))
                  )}
                </Form.Control>

                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                  textAlign: 'center',
                }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    border: '2px solid #dcdcdc',
                    padding: '8px',
                    backgroundColor: 'transparent',
                    width: 'fit-content',
                    height: 'fit-content',
                  }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}>
                    <MdFileUpload onClick={() => setShowModal(true)} size={'1.5em'} style={{ color: 'red' }} />
                  </div>
                  <span style={{ marginTop: '8px', fontWeight: 'bold' }}>Cargar nuevo autorizado</span>
                  {!selectedAuthorizedPerson && (
                    <span style={{
                      marginTop: '12px',
                      fontSize: '0.900rem',
                      color: '#dc3545',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      display: 'block',
                    }}>
                      * Debes seleccionar un autorizado para avanzar o puedes autorizar una nueva persona.
                    </span>
                  )}
                  <span style={{
                    marginTop: '12px',
                    fontSize: '0.875rem',
                    color: '#6c757d',
                    textAlign: 'center',
                    fontStyle: 'italic',
                    display: 'block',
                  }}>
                    * Los formatos permitidos son .png, .jpg, .jpeg, .pdf
                  </span>
                </div>
              </div>
            )}
          </div>
          <hr />
        </form>
      </div>

      {/* Modal solo si se selecciona la opción */}
      {showModal && <UploadDNIModal onClose={closeModal} />}
    </>
  );
};
