/* eslint-disable react/prop-types */
import React, { useContext } from "react";

import { Container, Row } from "./styles";
import { marcasContext } from "../../Context/MarcasContext";

const Hero = ({ data: marca }) => {
  const { codigoMarca } = useContext(marcasContext);
  const findBrand = codigoMarca.find(item => item.Descripcion === marca.Marca)

  return (
    <Container>

      <Row>
        <a href="/" className="volver">Inicio</a>
        <a href={`/marcas/${findBrand.Codigo}`}>{marca.Marca}</a>
      </Row>

    </Container>
  );
};

export default Hero;