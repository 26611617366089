/* eslint-disable react/prop-types */
import React from 'react';
import { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { cartContext } from '../Context/CartContext';
import { useTranslation } from 'react-i18next';

export const AddAndBuy = ({ producto }) => {
    const { addToCart, cartLocal } = useContext(cartContext)
    const navigate = useNavigate();
    const [t] = useTranslation("global")

    const itemInCart = cartLocal.length === 0 ? undefined : cartLocal.find(item => item.Alias === producto.Alias);

    const onAdd = () => {
        if (!itemInCart || itemInCart.length === 0 || producto.Stock - itemInCart.contador - 1 >= 0) {
            addToCart(producto, 1);
            navigate('/cart')
        }
        else {
            toast.error(t("producto.stock-insuficiente"), {
                className: 'custom-toast',
                autoClose: 1000
            });
        }
    }

    return (
        <Button variant='danger' id='addToCart' onClick={onAdd} className='botonesAll' style={{ display: 'none' }} >{t("producto.comprar")}</Button>
        /* BATALLA PARA OTRO MOMENTO */
    )
}