import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './EstiloPages.css';
import { SpinnerMarcas } from '../helpers/SpinnerMarcas';
import CatalogoDigital from '../CatalogoDigital/CatalogoDigital';

export const PdfCatalogoEng = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      <img
        src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"}
        alt="Imagen marcas"
        className='imagenesBody'
        loading='lazy'
      />
      <Container>
        {showLoader ? (
          <div>
            <h2>Loading the catalogue, please wait</h2>
            <SpinnerMarcas />
          </div>
        ) : <CatalogoDigital />}

      </Container>
    </div>
  );
};