import React, { useState, useRef, useContext, useCallback } from "react";
import { Button, Form, Row, Spinner, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import "./UploadDNI.css";
import { toast } from "react-toastify";
import { FaFileUpload } from "react-icons/fa";
import { authContext } from "../../Context/AuthContext";
import { globalContext } from "../../Context/GlobalContext";

import axios from "axios";
import Swal from "sweetalert2";
import useFetchAuthorizedPerson from "../../../utilities/hooks/useFetchAuthorizedPerson";


const UploadDNI = () => {
    const [files, setFiles] = useState({ front: null, back: null });
    const [step, setStep] = useState(1);
    const { isUploading, setIsUploading } = useContext(globalContext)
    const { user, token } = useContext(authContext);
    const { fetchAuthorizedPerson } = useFetchAuthorizedPerson()

    const [inputs, setInputs] = useState({
        clientId: "",
        nroDocumento: "",
        nombre: "",
        apellido: "",
        email: user.Email || "",
        nroTelefono: user.Domicilio_facturacion.Telefono || "",
        esTransportista: false,
        empresa: user.Cuit || "",
        frenteUrl: "",
        dorsoUrl: "",
    });

    const fileInputFrontRef = useRef(null);
    const fileInputBackRef = useRef(null);

    // Agregar un nuevo DNI 
    const addAuthorizedPerson = async (inputs, files) => {
        setIsUploading(true);
        const formData = new FormData();
        formData.append("clientId", (user && user.Cliente) ? user.Cliente : 123);
        formData.append("nroDocumento", inputs.nroDocumento);
        formData.append("nombre", inputs.nombre);
        formData.append("apellido", inputs.apellido);
        formData.append("email", inputs.email);
        formData.append("nroTelefono", inputs.nroTelefono || 0);
        formData.append("esTransportista", inputs.esTransportista);
        formData.append("empresa", inputs.empresa || "");
        formData.append("frente", files.front);
        formData.append("dorso", files.back);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_CLIENTE}/retira/dni`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log("Response:", response);
            if (response.status === 201) {
                fetchAuthorizedPerson();
                Swal.fire({
                    icon: "success",
                    title: "Documento enviado exitosamente",
                    text: "Su archivo ha sido cargado con éxito.",
                    confirmButtonColor: "#3b82f6",
                });
                return true;
            } else {
                console.error("Error en la respuesta:", response);
                toast.error("Hubo un error al enviar los datos. Intenta nuevamente.");
                return false;
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
            toast.error("Error al enviar los datos.");
            return false;
        } finally {
            setIsUploading(false);
        }
    };



    const handleFileChange = (step, e) => {
        const file = e.target.files[0];

        // Validar que el archivo es una imagen y que su formato es permitido
        const allowedTypes = ["image/jpeg", "image/png"];
        if (!allowedTypes.includes(file.type)) {
            toast.error("Solo se permiten archivos en formato JPG o PNG.");
            e.target.value = ""; // Limpiar el input
            return;
        }

        const newFiles = { ...files, [step]: file };
        setFiles(newFiles);

        if (step === "front" && newFiles.front) {
            setStep(2);
            toast.success("Frente cargado con éxito", {
                position: "bottom-left"
            });
        } else if (step === "back" && newFiles.back) {
            toast.success("Dorso cargado con éxito", {
                position: "bottom-left"
            });
        }
    };


    const handleIconClick = (step) => {
        if (step === "front") {
            fileInputFrontRef.current.click();
        } else if (step === "back") {
            fileInputBackRef.current.click();
        }
    };

    const isFilesReady = files.front && files.back && inputs.nombre && inputs.nroDocumento && inputs.apellido;

    const completedIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
    );

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setInputs(prevInputs => ({
            ...prevInputs,
            [name]: value
        }));
    }, []);

    const handleSubmit = async () => {
        if (!files.front || !files.back || !inputs.nombre || !inputs.nroDocumento || !inputs.apellido) {
            toast.error("Por favor, completa todos los campos y sube ambos archivos.");
            return;
        }

        const isSuccess = await addAuthorizedPerson(inputs, files);
        if (isSuccess) {
            setInputs({
                clientId: "",
                nroDocumento: "",
                nombre: "",
                apellido: "",
                email: user.Email || "",
                nroTelefono: user.Domicilio_facturacion.Telefono || "",
                esTransportista: false,
                empresa: user.Cuit || "",
            });
            setFiles({ front: null, back: null });
            setStep(1);
        }
    };

    // Mensaje de tooltip para icon de subir
    const renderTooltip = (props) => (
        <Tooltip id="tooltip-upload" {...props}>
            {step === 1 ? "Cargar el frente de DNI" : "Cargar el dorso del DNI"}
        </Tooltip>
    );

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: '1em' }}>
            {step === 1 && (
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                    <div className="icon-upload-container" onClick={() => handleIconClick("front")}>
                        <FaFileUpload size={40} color="#3b82f6" />
                    </div>
                </OverlayTrigger>
            )}
            {step === 2 && (
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                    <div className="icon-upload-container" onClick={() => handleIconClick("back")}>
                        <FaFileUpload size={40} color="#3b82f6" />
                    </div>
                </OverlayTrigger>
            )}
            <input
                type="file"
                ref={fileInputFrontRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange("front", e)}
            />
            <input
                type="file"
                ref={fileInputBackRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange("back", e)}
            />

            <div style={{ width: "80px", height: "2px", backgroundColor: "#60a5fa", margin: "10px 0" }}></div>

            <div className="stepper-profile-dni">
                <div className="step-profile-dni">
                    <div className={`circle-profile-dni ${step >= 1 ? "active" : ""} ${files.front ? "completed" : ""}`}>
                        {files.front ? completedIcon : "1"}
                    </div>
                    <p className="label-profile-dni">
                        {!files.front ? "Cargar frente" : "Frente cargado"}
                    </p>
                </div>
                <div className="line-profile-dni"></div>
                <div className="step-profile-dni">
                    <div className={`circle-profile-dni ${step >= 2 ? "active" : ""} ${files.back ? "completed" : ""}`}>
                        {files.back ? completedIcon : "2"}
                    </div>
                    <p className="label-profile-dni">
                        {!files.back ? "Cargar dorso" : "Dorso cargado"}
                    </p>
                </div>
                <div className="line-profile-dni"></div>
            </div>

            <Form style={{ marginTop: "20px" }}>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                name="nombre"
                                placeholder="Nombre"
                                value={inputs.nombre}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Apellido</Form.Label>
                            <Form.Control
                                type="text"
                                name="apellido"
                                placeholder="Apellido"
                                value={inputs.apellido}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Número de Documento</Form.Label>
                            <Form.Control
                                type="text"
                                name="nroDocumento"
                                placeholder="Número de Documento"
                                value={inputs.nroDocumento}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>¿Es Transportista?</Form.Label>
                            <Form.Control
                                as="select"
                                name="esTransportista"
                                value={inputs.esTransportista}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="false">No</option>
                                <option value="true">Sí</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Button
                    onClick={handleSubmit}
                    className="w-100"
                    variant="outline-danger"
                    disabled={!isFilesReady || isUploading}
                >
                    {isUploading ? (
                        <>
                            <Spinner animation="border" size="sm" />
                            Cargando...
                        </>
                    ) : (
                        "Enviar Documento"
                    )}
                </Button>
            </Form>
        </div>
    );
};

export default UploadDNI;
