import React, { useState, useEffect, useRef, useContext } from 'react';
import './Buscador.css';
import { useTranslation } from 'react-i18next';
import { getProductosPorBusquedaService, getCategoriasService, getProductosPorSKUService } from '../../services/Buscador';
import { globalContext } from '../Context/GlobalContext';

const Buscador = () => {
    const {
        searchInput,
        setSearchInput,
        suggestedProducts,
        setSuggestedProducts,
        suggestedBrands,
        setSuggestedBrands,
        suggestedCategories,
        setSuggestedCategories,
        categoria,
        setCategoria,
        suggestedSKUs,
        setSuggestedSKUs,
        categoriaLoaded,
        setCategoriaLoaded,
        userInteracted,
        setUserInteracted,
        closeSuggestions,
        handleSuggestionClick
    } = useContext(globalContext)
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const searchInputRef = useRef(null);
    let img = process.env.PUBLIC_URL + '/assets/sin-imagen.png';
    const elementosPorPagina = 7;
    // eslint-disable-next-line no-unused-vars
    const [t, i18n] = useTranslation("global")
    let debounceTimer;


    const handleGetProductosPorBusqueda = async () => {
        setLoading(true);
        try {
            const productos = await getProductosPorBusquedaService(searchInput, elementosPorPagina, 0);
            setSuggestedProducts(productos);
            localStorage.setItem('productos', JSON.stringify(productos));
        } catch (error) {
            setError('Failed to fetch products');
        } finally {
            setLoading(false);
        }
    };

    const handleGetCategorias = async (retryCount = 0) => {
        try {
            setLoading(true);
            const storedCategorias = localStorage.getItem('categorias-stored');
            if (storedCategorias) {
                setCategoria(JSON.parse(storedCategorias));
                setLoading(false);
                return;
            }
            // Si no estan almacenadas uso la solicitud desde el service
            const categoriasData = await getCategoriasService();
            setCategoria(categoriasData);
            setLoading(false);
        } catch (err) {
            if (retryCount < 3) {
                if (err?.response?.status === 500) {
                    console.log("Error en el servidor.");
                } else if (err?.response?.status === 429) {
                    console.log("Error código 429 demasiados intentos, aguarde.");
                    setTimeout(() => handleGetCategorias(retryCount + 1), 180000); // 3 minutos
                } else {
                    setError(err);
                    console.error('Error al obtener los datos:', err);
                    setTimeout(() => handleGetCategorias(retryCount + 1), 180000); // 3 minutos
                }
            } else {
                console.error('Se alcanzó el máximo de intentos. No se pudo obtener los datos:', err);
            }
            setLoading(false);
        }
    };

    const handleGetProductsPorSKU = async (input) => {
        setLoading(true);
        try {
            const skuData = await getProductosPorSKUService(input);
            console.log('SKU Data:', skuData);
            setSuggestedSKUs(skuData);
        } catch (error) {
            setError('Failed to fetch SKU data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!categoriaLoaded && categoria.length === 0 && !loading) {
            handleGetCategorias();
            setCategoriaLoaded(true);
        }
    }, [categoria, categoriaLoaded, loading]);

    useEffect(() => { //TIENE UN TIMEOUT PARA QUE CUANDO HAGA EL FETCHING DE PRODUCTOS NO SE CARGUE EL SERVIDOR
        const debounceSearch = () => {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                if (searchInput.length >= 3) {
                    handleGetProductosPorBusqueda(searchInput);
                    handleGetProductsPorSKU(searchInput);
                    if (categoria !== null) {
                        const filteredCategories = categoria.filter(category => category.Descripcion.toLowerCase().includes(searchInput.toLowerCase()));
                        setSuggestedCategories(filteredCategories);
                    }
                }
            }, 300); // Esperar 300 milisegundos antes de ejecutar la búsqueda
        };

        debounceSearch();

        return () => {
            clearTimeout(debounceTimer); // Limpiar el temporizador al desmontar el componente
        };
    }, [searchInput]);


    useEffect(() => { //OBTENGO LAS MARCAS Y LAS FILTRO
        const storedBrands = JSON.parse(localStorage.getItem('marca')) || [];
        const filteredBrands = storedBrands.filter(brand => brand.Descripcion.toLowerCase().includes(searchInput.toLowerCase()));
        setSuggestedBrands(filteredBrands);

    }, [searchInput]);

    useEffect(() => {
        document.addEventListener('click', closeSuggestions);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('click', closeSuggestions);
            document.removeEventListener('keydown', handleKeyDown);
        };

    }, []);

    const handleKeyDown = (e) => {
        if (userInteracted && e.key === 'Escape') {
            closeSuggestions();
        }
    };

    useEffect(() => {
        const handleInputFocus = () => {
            setUserInteracted(true);
        };

        searchInputRef.current.addEventListener('focus', handleInputFocus);

        return () => {

            searchInputRef.current.removeEventListener('focus', handleInputFocus);
        };

    }, []);

    return (
        <form className={`search-form ${searchInput ? 'active' : ''}`}>
            <div className="search-bar-container">
                <div className="search-input-container">
                    <input
                        type="text"
                        className={`search-input ${searchInput ? 'input-active' : ''}`}
                        placeholder={t("buscador.buscador1")}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                        onFocus={() => setUserInteracted(true)}
                        ref={searchInputRef}
                    />
                    <span className="search-icon" >
                        <i className={`fas ${searchInput ? 'fa-times' : 'fa-search'}`}></i>
                    </span>
                </div>
            </div>
            <div className={`suggested-results ${searchInput.length >= 2 ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
                {(searchInput.length >= 2 || suggestedCategories.length >= 0) && (
                    <div>
                        {suggestedCategories.map((category, index) => (
                            <div
                                key={index}
                                className="suggested-result"
                                onClick={() => handleSuggestionClick(category.Codigo, 'categoria')}
                            >
                                <div className="suggested-text">
                                    {category.Descripcion} (Categoria)
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {(searchInput.length >= 2 || suggestedBrands.length > 0) && (
                    <div>
                        {suggestedBrands.map((brand, index) => (
                            <div
                                key={index}
                                className="suggested-result"
                                onClick={() => handleSuggestionClick(brand.Codigo, 'marca')}
                            >
                                <div className="suggested-text">
                                    {brand.Descripcion} (Marca)
                                </div>
                            </div>
                        ))}

                        {suggestedProducts.map((product, index) => (
                            <div
                                key={index}
                                className="suggested-result"
                                onClick={() => handleSuggestionClick(product.Alias, 'producto')}
                            >
                                <div className="suggested-text">
                                    <img
                                        src={
                                            product.Imagenes
                                                ? process.env.PUBLIC_URL +
                                                `/articulos/thumbs/${encodeURIComponent(product.Imagenes.split(',')[0])}`
                                                : img
                                        }
                                        alt="sbox"
                                        className="imgThumb"
                                        loading="lazy"
                                    />
                                    {product.Nombre} (Producto)
                                </div>
                            </div>
                        ))}
                        {(searchInput.length >= 2 || suggestedSKUs.length > 0) && (
                            <div>
                                {suggestedSKUs.map((sku, index) => (
                                    <div
                                        key={index}
                                        className="suggested-result"
                                        onClick={() => handleSuggestionClick(sku.Alias, 'sku')}
                                    >
                                        <div className="suggested-text">
                                            <img
                                                src={
                                                    sku.Imagenes
                                                        ? process.env.PUBLIC_URL +
                                                        `/articulos/thumbs/${encodeURIComponent(sku.Imagenes.split(',')[0])}`
                                                        : img
                                                }
                                                alt="sbox"
                                                className="imgThumb"
                                                loading="lazy"
                                            />
                                            {sku.Nombre} (SKU)
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                )}
            </div>
        </form>
    );
};

export default Buscador;