import React from 'react'
import { Link } from 'react-router-dom'
import './FooterTestStyle.css'
import { data } from "../../config/config.js"
import { useTranslation } from 'react-i18next'


export const Footer = () => {

  const [t] = useTranslation("global")

  return (

    <footer className="footer">
      <div className="container bottom_border">
        <h1 style={{
          color: "white",
          textAlign: "center"
        }}>
          {data.name}
        </h1>
        <div className="row">
          <div className=" col-sm-4 col-md col-sm-4  col-12 col">
            <h5 className="headin5_amrc col_white_amrc pt2">{t("footer.titulo-1")}</h5>
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill='currentColor' className='svgFooter'>
                <path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z" />
              </svg>
              {data.address}
            </p>

            <p>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='currentColor' className='svgFooter'>
                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
              </svg>
              {data.phone}
            </p>

            <p>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='currentColor' className='svgFooter'>
                <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
              </svg>
              {data.mail}
            </p>
          </div>

          <div className=" col-sm-4 col-md  col-6 col">
            <h5 className="headin5_amrc col_white_amrc pt2">{t("footer.titulo-2")}</h5>
            <ul className="footer_ul_amrc">
              <li><a href="https://www.solutionbox.com.ar/" target='_blank' rel="noreferrer">Argentina</a></li>
              <li><a href="https://www.solutionbox.cl/" target='_blank' rel="noreferrer">Chile</a></li>
              <li><a href="https://www.solutionbox.cr/" target='_blank' rel="noreferrer">Costa Rica</a></li>
              <li><a href="http://www.solutionbox.com.sv/" target='_blank' rel="noreferrer">El Salvador</a></li>
              <li><a href="http://www.solutionbox.com.gt/" target='_blank' rel="noreferrer">Guatemala</a></li>
              <li><a href="http://www.solutionbox.com.hn/" target='_blank' rel="noreferrer">Honduras</a></li>
              <li><a href="https://www.solutionbox.com.hk/" target='_blank' rel="noreferrer">Hong Kong</a></li>
              <li><a href="http://www.solutionbox.com.py/" target='_blank' rel="noreferrer">Paraguay</a></li>
              <li><a href="http://www.solutionbox.com.pa/" target='_blank' rel="noreferrer">Panama</a></li>
              <li><a href="https://www.solutionbox.com.do/" target='_blank' rel="noreferrer">Republica Dominicana</a></li>
              <li><a href="https://www.solutionbox.com.uy/" target='_blank' rel="noreferrer">Uruguay</a></li>
              <li><a href="https://www.solutionboxusa.com/" target='_blank' rel="noreferrer">USA</a></li>
            </ul>
          </div>

          <div className=" col-sm-4 col-md  col-6 col">
            <h5 className="headin5_amrc col_white_amrc pt2">{t("footer.titulo-3")}</h5>

            <ul className="footer_ul_amrc">
              <li><Link to="nuestraempresa">{t("footer.empresa")}</Link></li>
              <li><Link to="trabajaconnosotros">{t("footer.trabaja-con-nosotros")}</Link></li>
              <li><Link to="rma">RMA</Link></li>
            </ul>
          </div>


          <div className=" col-sm-4 col-md  col-12 col">
            <h5 className="headin5_amrc col_white_amrc pt2">WEB</h5>

            <ul className="footer_ul_amrc">
              <li><a href={process.env.PUBLIC_URL + "pdf/catalogoHD.pdf"} target="_blank" rel="noreferrer">{t("footer.catalogo-digital")}</a></li>
              <li><Link to="capacitaciones">{t("footer.capacitaciones")}</Link></li>
              <li><Link to="/registroUsuario">{t("footer.alta-usuarios")}</Link></li>
            </ul>

          </div>
        </div>
      </div>


      <div className="container" style={{ width: "auto" }}>
        <p className="text-center">© 2024 Solution Box S.A | {data.address}</p>


        <section>

          <ul className="icon-list">
            <li className="icon-item">
              {/* PARAMETRIZADO */}
              <a href={data.RRSS.instagram} target='_blank' rel="noreferrer" className="icon-link"><i className="fab fa-instagram"></i></a>
            </li>
            <li className="icon-item">
              <a href={data.RRSS.facebook} target='_blank' rel="noreferrer" className="icon-link"><i className="fab fa-facebook-f"></i></a>
            </li>
            <li className="icon-item">
              <a href={data.RRSS.twitter} target='_blank' rel="noreferrer" className="icon-link"><i className="fab fa-twitter"></i></a>
            </li>
            <li className="icon-item">
              <a href={data.RRSS.youtube} target='_blank' rel="noreferrer" className="icon-link"><i className="fab fa-youtube"></i></a>
            </li>
            <li className="icon-item">
              <a href={data.RRSS.linkedin} target='_blank' rel="noreferrer" className="icon-link"><i className="fab fa-linkedin-in"></i></a>
            </li>
            <li className="icon-item">
              <a href={data.RRSS.tiktok} target='_blank' rel="noreferrer" className="icon-link"><i className="fab fa-tiktok"></i></a>
            </li>
          </ul>
        </section>

      </div>

    </footer>
  )
}
